import {
  Firestore,
  UsersCollection,
  UsersDoc,
  UsersUpdateEmail,
  UsersUpdatePassword,
  CIFsCollection,
  UserIsNonClientByEmail,
  UserIsSingleSignOn,
  GetCompanyByCif,
  UserExternalAuth,
  UpdateUserCertificate,
  DeleteUserCertificate,
} from '@/firebase-exports'

/**
 * Obtiene un usuario por su IDº
 *
 * @param {string} id - El ID del usuario.
 * @returns {Promise<Object>} - El objeto del usuario si existe, undefined si no existe.
 */
export async function getUser(id) {
  const docSnap = await Firestore.getDoc(UsersDoc(id))
  if (!docSnap.exists()) return
  const document = docSnap.data()
  document.cifs = await getCifs(id)

  // Delete some back variables that could be unsafe to store in front
  delete document.password
  delete document.passwordEmpresa
  delete document.redmineKey
  delete document.redmineProject

  return { id: docSnap.id, ...document }
}

/**
 * Obtiene la información de un usuario por su ID.
 *
 * @param {string} id - El ID del usuario.
 * @returns {Promise<Object>} - El objeto con la información del usuario si existe, undefined si no existe.
 */
export async function getUserInfo(id) {
  const docSnap = await Firestore.getDoc(UsersDoc(id))
  if (!docSnap.exists()) return
  const document = docSnap.data()

  // Delete some back variables that could be unsafe to store in front
  delete document.password
  delete document.passwordEmpresa
  delete document.redmineKey
  delete document.redmineProject

  return { id: docSnap.id, ...document }
}

/**
 * Exporta asincrónicamente la función "updateUserCertificate".
 *
 * @param {any} data - Los datos necesarios para la función.
 * @returns {Promise} - Una promesa que indica la finalización de la función.
 */
export async function updateUserCertificate(data) {
  await UpdateUserCertificate(data)
}

/**
 * Exporta asincrónicamente la función "deleteUserCertificate".
 *
 * @param {any} data - Los datos necesarios para la función.
 * @returns {Promise} - Una promesa que indica la finalización de la función.
 */
export async function deleteUserCertificate(data) {
  await DeleteUserCertificate(data)
}

/**
 * Obtiene los atributos de un padre para los operadores.
 *
 * @param {string} id - El ID del usuario padre.
 * @returns {Promise<Object>} Una promesa que se resuelve en un objeto que contiene
 *                            atributos específicos del usuario padre, como las
 *                            preferencias de la empresa y la información del certificado.
 */
export async function getParentAttributes(id) {
  // Used to get some parent attributes to operators
  const data = (await Firestore.getDoc(UsersDoc(id))).data()

  return {
    allowed2FA: data.allowed2FA,
    customBrand: data.customBrand,
    tipoEmpresa: data.tipoEmpresa,
    tipoDocIdentidadEmpresa: data.tipoDocIdentidadEmpresa,
    numeroDocIdentidadEmpresa: data.numeroDocIdentidadEmpresa,
    notificacionesActivasEmpresa: data.notificacionesActivasEmpresa,
    firmasActivasEmpresa: data.firmasActivasEmpresa,
    lastRetrievedNotifications: data.lastRetrievedNotifications,
    needsCertEmpresa: data.needsCertEmpresa,
    certificateFilename: data.certificateFilename,
    certificateExpiration: data.certificateExpiration,
    alternativeCertificates: data.alternativeCertificates,
    securitySettings: data.securitySettings,
    allowedAbsenceManagement: data.allowedAbsenceManagement,
    externalAuth: data.externalAuth ?? null,
  }
}

/**
 * Obtiene un usuario a partir de una referencia de Firestore.
 *
 * @async
 * @param {Object} ref - La referencia del documento del usuario en Firestore.
 * @returns {Promise<Object>} El objeto del usuario si existe, undefined si no existe.
 * El objeto del usuario incluye el id y todos los datos del usuario, excepto
 * password, passwordEmpresa, redmineKey y redmineProject que son eliminados por seguridad.
 */
export async function getUserWithRef(ref) {
  const docSnap = await Firestore.getDoc(ref)
  if (!docSnap.exists()) return
  const document = docSnap.data()

  // Delete some back variables that could be unsafe to store in front
  delete document.password
  delete document.passwordEmpresa
  delete document.redmineKey
  delete document.redmineProject

  return { id: docSnap.id, ...document }
}

/**
 * Obtiene un usuario basándose en su correo electrónico.
 *
 * @param {string} email - El correo electrónico del usuario.
 * @returns {Promise<Object|undefined>} Una promesa que se resuelve en un objeto que contiene
 *                                      los datos del usuario si este existe, o undefined si no se encuentra.
 */
export async function getUserByEmail(email) {
  const q = Firestore.query(
    UsersCollection,
    Firestore.where('email', '==', email.toLowerCase())
  )
  const querySnapshot = await Firestore.getDocs(q)
  if (!querySnapshot.empty) {
    const id = querySnapshot.docs[0].id
    const document = querySnapshot.docs[0].data()

    // Delete some back variables that could be unsafe to store in front
    delete document.password
    delete document.passwordEmpresa
    delete document.redmineKey
    delete document.redmineProject

    return { id, ...document }
  } else {
    return undefined
  }
}

/**
 * Obtiene de manera asíncrona los clientes asociados a un usuario específico. Esta función busca
 * en la colección de usuarios aquellos que tienen un tipo específico ('clienteDespacho') y una
 * referencia de padre que coincide con el ID del usuario proporcionado.
 *
 * @param {string} userId - El ID del usuario para el cual se están buscando los clientes.
 * @returns {Promise<Array>} Una promesa que se resuelve en un arreglo de objetos. Cada objeto
 *                           representa un cliente, conteniendo su ID y los datos asociados
 *                           obtenidos de Firestore.
 */
export async function getUserClients(userId) {
  const q = Firestore.query(
    UsersCollection,
    Firestore.where('tipo', '==', 'clienteDespacho'),
    Firestore.where('parentRef', '==', UsersDoc(userId))
  )
  const querySnapshot = await Firestore.getDocs(q)

  return querySnapshot.docs.map((client) => ({
    id: client.id,
    ...client.data(),
  }))
}

/**
 * Obtiene las empresas basándose en el ID de usuario especificado.
 * Opcionalmente, filtra los CIFs si se proporciona un objeto operador.
 *
 * @param {string} userId - El ID del usuario para el cual se están recuperando los CIFs.
 * @param {Object} [operator=false] - Opcional. Un objeto que representa a un operador.
 *                                    Si se proporciona, la función filtrará los CIFs basándose
 *                                    en las referencias de CIF del operador.
 * @returns {Promise<Array>} Una promesa que se resuelve en un arreglo de objetos CIF. Cada objeto
 *                           contiene el ID y los datos del CIF, excluyendo cualquier información
 *                           sensible como contraseñas.
 */
export async function getCifs(userId, operator = false) {
  var cifs = []

  const q = Firestore.query(
    CIFsCollection(userId),
    Firestore.orderBy('empresa', 'asc')
  )
  const querySnapshot = await Firestore.getDocs(q)

  await querySnapshot.docs.map(async (doc) => {
    const document = doc.data()

    // Delete some back variables that could be unsafe to store in front
    delete document.password

    // Si no es operador guardamos los cifs de la subcolección
    if (!operator) cifs.push({ id: doc.id, ...document })
    // Si es operator, guardamos los cifs dadas las referencias del operador
    else if (operator.cifsRef.some((cif) => cif.id === doc.id))
      cifs.push({ id: doc.id, ...document })
  })

  return cifs
}

/**
 * Obtiene los grupos de un operador.
 *
 * @param {Object} operator - El operador del que se obtendrán los grupos.
 * @returns {Promise<Array>} - Un array con los grupos del operador.
 */
export async function getOperatorGroups(operator) {
  var groups = []

  // Obtenemos los grupos dadas las referencias del usuario
  for (let i = 0; i < operator.groupsRef?.length; i++) {
    const groupRef = operator.groupsRef[i]

    try {
      const doc = await Firestore.getDoc(groupRef)
      const document = {
        id: doc.id,
        ...doc.data(),
      }

      groups.push(document)
    } catch (err) {
      // Referencia invalida
    }
  }

  return groups
}

/**
 * Actualiza el correo electrónico de un usuario.
 *
 * @param {string} newEmail - El nuevo correo electrónico.
 * @returns {Promise<Object>} - El resultado de la operación de actualización.
 */
export async function updateUserEmail(newEmail) {
  return (await UsersUpdateEmail(newEmail)).data
}

/**
 * Actualiza la contraseña de un usuario.
 *
 * @param {Object} newPasswordObject - Objeto con la nueva contraseña, mail y un flag para generar una contraseña aleatoria.
 * @returns {token} - Token generado.
 */
export async function updateUserPassword(newPasswordObject) {
  return await UsersUpdatePassword(newPasswordObject)
}

/**
 * Actualiza la información de un usuario.
 *
 * @param {string} userId - El ID del usuario.
 * @param {Object} data - Los nuevos datos del usuario.
 * @returns {Promise<void>} - Una promesa que indica la finalización de la función.
 */
export async function updateUserInfo(userId, data) {
  Firestore.updateDoc(UsersDoc(userId), data)
}

/**
 * Elimina el avatar de un usuario.
 *
 * @param {string} userId - El ID del usuario.
 * @returns {Promise<void>} - Una promesa que indica la finalización de la función.
 */
export async function removeAvatar(userId) {
  Firestore.updateDoc(UsersDoc(userId), {
    avatarURL: null,
    avatarRef: '',
  })
}

/**
 * Comprueba si un correo electrónico no pertenece a un cliente.
 *
 * @param {string} email - El correo electrónico a comprobar.
 * @param {string} userId - El ID del usuario.
 * @returns {Promise<boolean>} - True si el correo electrónico no pertenece a un cliente, false en caso contrario.
 */
export async function isNonClientByEmail(email, userId) {
  const { data } = await UserIsNonClientByEmail({ email, userId })
  return data
}

/**
 * Función para validar un usuario por su email y, si existe, devolver el atributo singleSignOn.
 *
 * @param {string} email - El email a validar
 * @returns {Promise<boolean>} - Devuelve atributo booleano singleSignOn
 */
export async function validateUserSingleSignOn(email) {
  const { data } = await UserIsSingleSignOn({ email })
  return data
}

/**
 * Busca una empresa por su cif en la coleccion 'cifs' y devuelve sus atributos
 *
 * @param {string} userId - El ID del usuario.
 * @param {string} cif - El Cif a buscar
 * @returns {Promise<Object|undefined>} Una promesa que se resuelve en un objeto que contiene
 *                                      los datos de la empresa si esta existe, o undefined si no se encuentra.
 */
export async function validateCompanyByCif(userId, cif) {
  const { data } = await GetCompanyByCif({ userId, cif })
  return data
}

/**
 * Función para validar si un usuario puede realizar una autenticación externa con su email y, si lo tiene permitido,
 * devolver los valores para poder realizarla.
 *
 * @param {string} email - El email a validar
 * @returns {Promise<boolean>} - Devuelve un objeto con los campos para realizar la autenticacion externa.
 */
export async function validateExternalAuth(email) {
  const { data } = await UserExternalAuth({ email })
  return data
}

/**
 * Obtiene la referencia a un usuario por su ID.
 *
 * @param {string} id - El ID del usuario.
 * @returns {<Object>} - El objeto con la referencia al documento.
 */
export function getUserRef(id) {
  return UsersDoc(id)
}
