<template>
  <v-dialog :value="true" persistent :max-width="dialogWidth">
    <v-card class="pa-0">
      <v-card-title class="justify-center">
        <v-row align="center">
          <v-col cols="1"> </v-col>
          <v-col cols="10" class="d-flex justify-center">
            <span v-if="!this.isAbsence">
              {{ $t('operatorActions.title.' + action) }}</span
            >
            <DialogButton v-if="is2FA" section="multifactor" :hasPage="false" />
          </v-col>
          <v-col cols="1" class="d-flex justify-end">
            <EditCancel
              v-if="isDisplay"
              :isBeingEdited="userIsEditing"
              :editText="$t('operatorActions.title.update')"
              @handleClick="userIsEditing = !userIsEditing"
            />
            <v-tooltip bottom v-else-if="isActivity">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="primary secondary--text"
                  fab
                  small
                  @click.stop="exportDataTrace"
                  id="export-trace"
                >
                  <v-icon> mdi-file-export </v-icon>
                </v-btn>
              </template>
              <div>
                {{ $t('operatorActions.title.exportDataTrace') }}
              </div>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-title>
      <template>
        <v-card-text
          :class="
            !isCreateCSV ? 'text-center ' + (isMobile ? 'pl-0 pr-0' : '') : ''
          "
        >
          <DeleteEnableInfo
            v-if="isDelete || isEnable"
            :operator="operatorProp"
          />
          <ActivityInfo
            v-else-if="isActivity"
            :loginInfo="loginInfo"
            :openedNotifications="openedNotificationsData"
          />
          <OperatorAbsenceList
            v-else-if="isAbsence"
            :operator="operatorProp"
            :operators="operators"
            :substituteCache="substituteCache"
            :absencesTraces="absencesTraces"
          />
          <OperatorBulk
            v-else-if="isCreateCSV"
            :loading="loading"
            :log="log"
            :groups="groups"
            @setCSVData="setCSVData"
          />
          <v-form
            ref="multifactorForm"
            v-else-if="is2FA"
            v-model="validMultifactorForm"
            lazy-validation
          >
            <Fa2Form
              :loading="loading"
              :enable2FAProp="!!this.operatorProp?.multiFactor"
              :multiFactorProp="this.operatorProp?.multiFactor"
              @setMultifactor="(value) => (multiFactor = value)"
              @setEnable2FA="(value) => (enable2FA = value)"
            />
          </v-form>

          <v-form
            v-else-if="isCreate || isUpdate || isDisplay"
            class="ma-0 pa-0 px-10"
            ref="operatorForm"
            v-model="validOperatorForm"
            lazy-validation
          >
            <OperatorForm
              ref="operatorFormComponent"
              :isCreate="isCreate"
              :isDisplay="isDisplay"
              :isUpdate="isUpdate"
              :userIsEditing="userIsEditing"
              :operator="operatorProp"
              :operators="operators"
              :filters="filters"
              :groupsMap="groupsMap"
              :companies="companies"
              :groups="groups"
              :cifsMap="cifsMap"
              :externalsAuths="externalsAuths"
              @setActivas="setActivas"
              @setOperatorData="setOperatorData"
            />
            <NoFilterDialog v-if="showDialog" @userChoice="confirmUpdate" />
          </v-form>

          <!-- alerta control de operador no permite ser deshabilitado, mensaje distinto segun el caso -->
          <div
            class="mt-12"
            style="display: flex; justify-content: center; width: 100%"
            v-if="isDelete && isSubstituteForSomeone"
          >
            <v-alert
              type="error"
              color="red"
              style="max-width: 90%"
              v-html="
                $t('operatorCannotBeDisabledLine1') +
                '<br />' +
                $t('blockedByAbsence', {
                  fullname: getOperatorFullname(
                    operatorSubstituteFor.substituteForId
                  ),
                  startDate: formatDate(operatorSubstituteFor.initialDate),
                  endDate: formatDate(operatorSubstituteFor.finalDate),
                }) +
                '<br />' +
                '<br />' +
                $t('operatorCannotBeDisabledLine3')
              "
            >
            </v-alert>
          </div>

          <div
            class="mt-12"
            style="display: flex; justify-content: center; width: 100%"
            v-else-if="isDelete && hasOperatorAbsences"
          >
            <v-alert
              type="error"
              color="red"
              style="max-width: 90%"
              v-html="
                $t('operatorCannotBeDisabledLine2') +
                '<br />' +
                $t('operatorCannotBeDisabledLine3')
              "
            >
            </v-alert>
          </div>
        </v-card-text>
      </template>

      <v-card-actions class="headline justify-center">
        <v-btn
          :disabled="loading"
          color="error"
          rounded
          class="white--text"
          width="150px"
          @click="closeDialog(true)"
        >
          {{ isActivity || isAbsence ? $t('close') : $t('cancel') }}
        </v-btn>
        <v-btn
          v-if="!isActivity && !isAbsence"
          rounded
          color="accept"
          class="white--text"
          width="150px"
          :loading="loading"
          :disabled="
            loading ||
            (!userIsEditing && isDisplay) ||
            (isCreateCSV && !csvData?.file) ||
            (isDelete && (isSubstituteForSomeone || hasOperatorAbsences))
          "
          @click="performAction"
          id="confirm-operator"
        >
          {{ $t('confirm') }}
          <template v-slot:loader>
            <v-progress-circular indeterminate size="20" width="2" />
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  createUserOperator,
  createUserOperatorBulk,
  disableOperator,
  updateUserOperatorEmail,
  updateUserOperator,
  updateUserOperatorMultifactor,
  updateUserOperatorPassword,
} from '@/services/operator-service'
import DialogButton from '@/components/documentation/buttons/DialogButton'
import EditCancel from '@/components/commons/EditCancel'
import DeleteEnableInfo from '@/components/operators/dialog/DeleteEnableInfo'
import OperatorForm from '@/components/operators/dialog/OperatorForm'
import ActivityInfo from '@/components/operators/dialog/ActivityInfo'
import OperatorBulk from '@/components/operators/dialog/OperatorBulk'
import Fa2Form from '@/components/operators/dialog/Fa2Form'
import { getUserTraceSubscription } from '@/services/trace-service'
import { mapGetters, mapMutations } from 'vuex'
import getErrorText from '@/utils/get-error-text'
import { checkOperatorExistence } from '../../services/groups-service'
import NoFilterDialog from '@/components/operators/dialog/NoFilterDialog'
import OperatorAbsenceList from '@/components/operators/OperatorAbsenceList'
import { getUserWithRef } from '@/services/user-service'

export default {
  props: {
    action: String,
    operators: Array,
    actionDialog: String,
    operatorProp: {
      type: Object,
      default: () => ({}),
    },
    companies: Array,
    companiesDict: Object,
    filters: Array,
    groupsMap: Object,
    groups: Array,
    cifsMap: Object,
    externalsAuths: Array,
    substituteCache: Array,
  },
  components: {
    DialogButton,
    EditCancel,
    DeleteEnableInfo,
    ActivityInfo,
    OperatorBulk,
    Fa2Form,
    OperatorForm,
    NoFilterDialog,
    OperatorAbsenceList,
  },
  destroyed() {
    if (this.operatorTraceUnsubscribe) this.operatorTraceUnsubscribe()
  },
  data() {
    return {
      validOperatorForm: false,
      validMultifactorForm: false,
      userIsEditing: false,
      loading: false,
      selected: [],
      enable2FA: !!this.operatorProp.multiFactor,
      multiFactor: [],
      operatorTrace: [],
      operatorData: {},
      operatorTraceUnsubscribe: () => {},
      firmasActivas: false,
      notificacionesActivas: false,
      adminPermissions: false,
      log: [],
      csvData: {},
      isEmptyFilter: false,
      showDialog: false,
      isSubstituteForSomeone: false,
      operatorSubstituteFor: {},
      openedNotificationsData: [],
    }
  },
  async mounted() {
    if (this.isActivity || this.isAbsence)
      this.operatorTraceUnsubscribe = await getUserTraceSubscription(
        this.operatorProp.id,
        this
      )

    this.isSubstituteForSomeone = this.operatorSubstituteForSomeone()
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    /**
     * Sets a value in the operator data.
     * @param key Attribute of the operator to modify
     * @param value Value to set.
     */
    setOperatorData(key, value) {
      this.operatorData[key] = value
    },
    setCSVData(data) {
      this.csvData = data
    },
    exportDataTrace() {
      var exportTrace = this.operatorTrace.map((trace) => {
        return {
          createdAt: trace.createdAt.toLocaleDateString(this.$i18n.locale, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          }),
          actionType: this.$i18n.t(`csv.trace.${trace.actionType}`),
          info: trace.actionType === 'logged' ? trace.ip : trace.notificationId,
        }
      })
      if (exportTrace.length < 1) {
        const message = this.$t('nothingToExport')
        this.setSnackbar({ position: 'top', type: 'info', message })
        return
      }
      const replacer = (key, value) => (value === null ? '' : value) // valores undefined

      //header auxiliar con el nombre de las variables
      const header = ['createdAt', 'actionType', 'info']
      //header a mostrar en el csv
      const header2 = [
        this.$i18n.t('csv.trace.createdAt'),
        this.$i18n.t('csv.trace.actionType'),
        this.$i18n.t('csv.trace.info'),
      ]

      const csv = [
        header2.join(';'), // asignamos separador
        ...exportTrace.map((row) =>
          header
            .map((fieldName) => JSON.stringify(row[fieldName], replacer))
            .join(';')
        ),
      ].join('\r\n')
      // Creamos elemento html
      const link = document.createElement('a')
      // Creamos el objeto blob con el contenido deseado (el array de objetos pasado a cadena de texto en formato csv)
      const file = new Blob([csv], { type: 'text/plain' })
      // añadimos el objeto a la url
      link.href = URL.createObjectURL(file)
      // le damos nombre
      link.download = 'trace.csv'
      // Se descarga el objeto forzando un click en el elemento y eliminamos la url.
      link.click()
      URL.revokeObjectURL(link.href)
      const message = this.$t('exportComplete')
      this.setSnackbar({ position: 'top', type: 'success', message })
      return
    },
    checkServicesAndAdminRoleSelection(operator) {
      let hasAdminRoleSelected = false
      const hasAdminPermissions = operator.adminPermissions
      if (hasAdminPermissions)
        hasAdminRoleSelected = Object.values(operator.adminRoles).some(
          (value) => value
        )
      if (
        hasAdminPermissions &&
        !operator.notificacionesActivas &&
        !operator.firmasActivas
      ) {
        this.setSnackbar({
          position: 'top',
          type: 'error',
          message: this.$t('adminPermissionsRequireService'),
        })
        return false
      }
      if (hasAdminPermissions && !hasAdminRoleSelected) {
        this.setSnackbar({
          position: 'top',
          type: 'error',
          message: this.$t('selectAtLeastOneAdminRole'),
        })
        return false
      }
      return true
    },
    async performAction() {
      // Comprobamos los campos del formulario
      if (
        !this.isCreateCSV &&
        !this.isDelete &&
        !this.isEnable &&
        !this.is2FA &&
        !this.$refs.operatorForm?.validate()
      )
        return

      this.checkAndUpdate()
    },
    async updateData() {
      // Removing the undefined fields
      const operator = {}

      for (const key in this.operatorData)
        if (
          typeof this.operatorData[key] !== 'undefined' &&
          this.operatorData[key] !== null
        )
          operator[key] = this.operatorData[key]

      // If no admin permissions, remove adminRoles
      if (!operator.adminPermissions) delete operator.adminRoles

      try {
        this.loading = true
        const userId = this.isOperatorUser
          ? this.$store.state.user.parentRef.id
          : this.$store.state.user.id

        if (this.isCreate) {
          // Checking if the user is an admin operator and has groups
          if (this.isInGroupUser) {
            // Checking if the email is registered in another group
            const operatorExists = await checkOperatorExistence(
              'operator',
              operator.email
            )
            if (operatorExists) {
              this.setSnackbar({
                position: 'top',
                type: 'error',
                message: this.$t(
                  operatorExists.isOperator
                    ? 'operatorAlreadyExistsInAnotherGroup'
                    : 'emailIsUsed'
                ),
                timeout: 10000,
              })
              return
            }
          }

          // Comprobamos selecciones de servicios y permisos e administrador
          // al igual que los roles posibles
          if (!this.checkServicesAndAdminRoleSelection(operator)) return

          // En el caso de que haya seleccionado un grupo
          // no se le pueden asignar permisos de gestion de grupos
          // y gestion de tareas automaticas
          if (operator.adminRoles && operator.groupsIds?.length > 0) {
            operator.adminRoles.manageGroups = false
            operator.adminRoles.manageAutomatedTasks = false
            operator.adminRoles.manageAdmins = false
          }

          await createUserOperator(userId, operator)
        } else if (this.isUpdate || this.isDisplay) {
          // Comprobamos selecciones de servicios y permisos de administrador
          // al igual que los roles posibles
          if (!this.checkServicesAndAdminRoleSelection(operator)) return

          // Actualizamos email
          if (operator.email && operator.email !== this.operatorProp.email)
            await updateUserOperatorEmail(this.operatorProp.id, operator.email)

          const hasGenerateToken = operator?.externalAuth?.allowed ?? false
          //Actualizamos contraseña
          if (operator.password || hasGenerateToken) {
            const token = await updateUserOperatorPassword(
              this.operatorProp.id,
              operator.password,
              operator.email,
              hasGenerateToken
            )
            if (hasGenerateToken) {
              operator.externalAuth.token = token.data
            }
          }

          delete operator.password

          await updateUserOperator(this.operatorProp.id, operator)
        } else if (this.isCreateCSV) {
          const result = await createUserOperatorBulk(
            userId,
            this.csvData.file,
            [this.csvData.group]
          )
          this.log = result.data.split('\n')
        } else if (this.isDelete) {
          await disableOperator(this.operatorProp.id, true)
        } else if (this.isEnable) {
          await disableOperator(this.operatorProp.id, false)
        } else if (this.is2FA) {
          if (!this.$refs.multifactorForm?.validate()) return
          await updateUserOperatorMultifactor(
            this.operatorProp.id,
            this.enable2FA ? this.multiFactor : null
          )
        }
        // Mostramos snackbar con el mensaje de exito
        const message = this.$t('operatorActions.success.' + this.actionDialog)
        this.setSnackbar({ position: 'top', type: 'success', message })
        if (!this.isCreateCSV) this.closeDialog()
      } catch (error) {
        // Mostramos snackbar con el mensaje de error
        const message = getErrorText(error.message)
        this.setSnackbar({ position: 'top', type: 'error', message })
      } finally {
        this.loading = false
      }
    },
    closeDialog() {
      this.$emit('closeOperatorDialog')
    },
    /**
     * Sets the values notificaciones activas y firmasActivas in the component
     * @param {Object} value Object with the values of firmasActivas and notificacionesActivas.
     */
    setActivas(value) {
      this.firmasActivas = value.firmasActivas
      this.notificacionesActivas = value.notificacionesActivas
      this.adminPermissions = value.adminPermissions
    },
    async checkAndUpdate() {
      this.isEmptyFilter = this.isFilterEmpty()
      if (this.isEmptyFilter) {
        // Mostrar diálogo si el array de filtros está vacío
        this.showDialog = true
      } else {
        // Proceder con la actualización si no está vacío
        await this.updateData()
      }
    },
    async confirmUpdate(response) {
      this.showDialog = false // Cerrar el diálogo
      if (response === 'accept') {
        // Si el usuario acepta, continuar con la actualización, grabando el array de filtros vacio
        await this.updateData()
      } else {
        // Si el usuario decide corregir, dar el foco al campo
        this.$refs.operatorFormComponent.focusOnEmptyFilterArray()
      }
    },
    isFilterEmpty() {
      return (this.isCreate || this.isUpdate || this.isDisplay) &&
        this.operatorData.notificacionesActivas
        ? !this.operatorData.filtersPath ||
            this.operatorData.filtersPath.length === 0
        : false
    },
    operatorSubstituteForSomeone() {
      // Esta funcion sirve para cumplir con el siguiente punto de los requisitos:
      //
      //    Cuando se deshabilita a un operador hay que comprobar si es sustituto de alguien.
      //    Si es sustituto de alguien no se permite deshabilitar. Antes tiene que ir a los
      //    operadores sustituidos y cancelar la sustitución.
      //
      // No se dice nada de rango fechas.

      let substitutions = null

      // Revisamos si este operador esta como sustituto en alguna ausencia de otro operador, en cualquier momento.
      this.operators.some((op) => {
        return op.absences?.some((absence) => {
          if (
            absence.substituteRef.id === this.operatorProp.id // El operador esta sustituyendo a alguien
          ) {
            substitutions = absence
            substitutions.substituteForId = op.id // guardamos el operador al que sustituye

            return true // Detenemos la busqueda al encontrar la primera sustitucion
          }
        })
      })

      this.operatorSubstituteFor = substitutions
      return !!substitutions
    },
    getOperatorFullname(id) {
      let fullname
      // Lo mas probable es que el operador sustituto se encuentre en la lista de operadores,
      const oper = this.operators.find((o) => o.id === id)
      if (oper) {
        fullname = `${oper.name || ''} ${oper.surname || ''}`
      } else {
        // Si no está en la lista de operadores entonces comprueba si ya está cargado en caché
        if (this.substituteCache[id]) {
          fullname = this.substituteCache[id]
        } else {
          fullname = 'No se encontró el sustituto'
        }
      }
      return fullname
    },
    formatDate(dateString) {
      if (!dateString) return ''
      const [year, month, day] = dateString.split('-')
      return `${day}-${month}-${year}`
    },
    async openedNotifications() {
      const opened = this.operatorTrace.filter(
        (elem) => elem.actionType == 'openNotification'
      )
      // resuelve los nombres de los usuarios ausentes que pudiera haber
      return Promise.all(
        opened.map(async (item) => {
          if (!item.absentUserRef) {
            return { ...item, absentUserFullname: undefined }
          }

          const user = await getUserWithRef(item.absentUserRef)
          return {
            ...item,
            absentUserFullname: user
              ? `${user.name || ''} ${user.surname || ''}`
              : undefined,
          }
        })
      )
    },
  },
  watch: {
    operatorTrace: {
      immediate: true, // Para ejecutar el watch al montar el componente
      handler: async function () {
        this.openedNotificationsData = await this.openedNotifications()
      },
    },
  },
  computed: {
    ...mapGetters([
      'isMobile',
      'isOperatorUser',
      'isInGroupUser',
      'isNotificacionesActivasEmpresa',
      'isFirmasActivasEmpresa',
    ]),
    isCreate() {
      return this.actionDialog === 'create'
    },
    isCreateCSV() {
      return this.actionDialog === 'create-csv'
    },
    isUpdate() {
      return this.actionDialog === 'update'
    },
    isDelete() {
      return this.actionDialog === 'delete'
    },
    isEnable() {
      return this.actionDialog === 'enable'
    },
    isDisplay() {
      return this.actionDialog === 'display'
    },
    is2FA() {
      return this.actionDialog === 'multifactor'
    },
    isActivity() {
      return this.actionDialog === 'activity'
    },
    isAbsence() {
      return this.actionDialog === 'absence'
    },
    loginInfo() {
      return this.operatorTrace.filter((elem) => elem.actionType == 'logged')
    },
    dialogWidth() {
      //if (this.isEnable || this.isDelete) return 400
      if (this.isEnable) return 400
      if (this.isDelete) {
        if (this.operatorProp.absences?.length) return 800
        else return 400
      } else if (this.is2FA) return 600
      else if (
        this.firmasActivas ||
        this.notificacionesActivas ||
        this.adminPermissions
      )
        return 1800
      else if (this.isActivity) return 1600
      else if (this.isCreateCSV) return 1200
      else if (this.isAbsence) return 1200
      else return 900
    },
    absencesTraces() {
      return this.operatorTrace.filter(
        (elem) =>
          elem.actionType == 'createAbsence' ||
          elem.actionType == 'cancelAbsence' ||
          elem.actionType == 'startAbsence' ||
          elem.actionType == 'terminateAbsence'
      )
    },
    hasOperatorAbsences() {
      return !!this.operatorProp.absences?.length
    },
  },
}
</script>
<style scoped>
.v-list {
  height: 200px;
  overflow-y: auto;
}
</style>
