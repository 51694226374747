<template>
  <div>
    <div
      v-for="(progress, index) in items"
      :key="index"
      :class="{ 'mt-1': index > 0 }"
    >
      <div v-if="progress.isDisplayed">
        <v-progress-linear
          :model-value="progress.movement ? undefined : 100"
          :indeterminate="progress.movement"
          :color="progress.color"
          rounded
          :height="progress.message ? progress.height : 5"
        >
          <div class="text-container mx-4" ref="textElement">
            <strong v-html="formattedMessages[index].message"></strong>

            <!-- Mostrar botones con tooltip solo si hay usuarios a los que sustituir -->
            <template v-if="formattedMessages[index].users.length">
              <v-tooltip
                v-for="user in formattedMessages[index].users"
                :key="user.id"
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    text
                    color="primary"
                    @click="toggleSelection(user)"
                    class="btn-substitute mx-1 mb-1"
                    :class="{ 'selected-btn': selectedUser?.id === user?.id }"
                  >
                    <v-icon v-if="selectedUser?.id === user?.id" left
                      >mdi-eye</v-icon
                    >
                    {{ user.fullname }}
                  </v-btn>
                </template>
                <div v-html="tooltipSubstitutionButton(user)"></div>
              </v-tooltip>
            </template>

            <!-- en caso de que exista un enlace -->
            <a
              v-if="progress.link"
              @click="$router.push(progress.link.route)"
              style="text-decoration: underline; margin-left: 10px"
            >
              <b>{{ $t(progress.link.text) }}</b>
            </a>
          </div>
        </v-progress-linear>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedUser: null,
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
    // espera a que Vue haya terminado de renderizar el DOM completamente antes de ejecutar la función
    this.$nextTick(() => {
      this.handleResize()
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  computed: {
    formattedMessages() {
      return this.items.map((item) => {
        // Formatear el mensaje solo si tiene params, si no, usar la traducción del mensaje original
        return item.messageParams
          ? this.formatMessage(item.message, item.messageParams)
          : { message: this.$t(item.message), users: [] }
      })
    },
  },
  methods: {
    handleResize() {
      this.items.forEach((item, index) => {
        if (item.message == 'updatingNotificationsText') {
          // si esta "Obteniendo nuevas notificaciones..." entonces no hacer nada y saltar al siguiente elemento que hubiera.
          return
        }

        // ajustar la altura del componente v-progress-linear
        // guardamos el valor original para poder restaurarlo mas tarde si es necesario
        if (item.originalHeight === undefined) {
          item.originalHeight = item.height
        }

        // Seleccionar un elemento de texto para obtener los estilos
        const textElement = this.$refs.textElement[index]
        const textHeight = textElement ? textElement.offsetHeight : 0

        // Usa textHeight para calcular la altura del componente
        item.height = Math.max(
          textHeight,
          item.originalHeight || item.originalHeight
        )
      })
    },
    formatMessage(message, messageParams) {
      let translatedMessage = this.$t(message, messageParams)
      // Comprueba si el objeto 'messageParams' contiene una clave llamada 'substitutions' que es de tipo array.
      if (
        Object.prototype.hasOwnProperty.call(messageParams, 'substitutions') &&
        Array.isArray(messageParams.substitutions)
      ) {
        return {
          message: translatedMessage,
          users: messageParams.substitutions.map((user) => ({
            id: user.substituteRef.id,
            fullname: user.fullname,
            email: user.email,
            initialDate: user.initialDate,
            finalDate: user.finalDate,
          })),
        }
      } else {
        // Extraer los parámetros de estilo
        const { hightlightTextColor, hightlightTextBackground } = messageParams
        // Asignar valores cuando vengan en los parámetros de estilo
        const styleColor = hightlightTextColor
          ? `color: ${hightlightTextColor} !important;`
          : ''
        const styleBackground = hightlightTextBackground
          ? `background-color: ${hightlightTextBackground};`
          : ''

        // Buscar y resaltar fechas en formato dd/mm/aaaa
        translatedMessage = translatedMessage.replace(
          /\b\d{2}\/\d{2}\/\d{4}\b/g,
          (match) => {
            return `<span class="highlight--custom" style="${styleColor} ${styleBackground}">${match}</span>`
          }
        )
        // Buscar y resaltar numeros de dos digitos que no formen parte de una fecha
        translatedMessage = translatedMessage.replace(
          /(?<!\/)\b\d{2}\b(?!\/)/g,
          (match) => {
            return `<span class="highlight--custom" style="${styleColor} ${styleBackground}">${match}</span>`
          }
        )

        return { message: translatedMessage, users: [] }
      }
    },
    tooltipSubstitutionButton(item) {
      // Mostrar en un tooltip multilinea información sobre el operador al que sustitye.
      const tooltipSubstituteButtonLine1 = this.$t(
        'tooltipSubstituteButtonLine1',
        {
          initialDate: item.initialDate,
          finalDate: item.finalDate,
        }
      )

      const tooltipSubstituteButtonLine2 = this.$t(
        'tooltipSubstituteButtonLine2',
        {
          fullname: item.fullname || '',
        }
      )

      const tooltipSubstituteButtonLine3 = this.$t(
        'tooltipSubstituteButtonLine3',
        {
          email: item.email || '',
        }
      )

      const tooltipSubstituteButtonLine4 = this.$t(
        'tooltipSubstituteButtonLine4',
        {
          status: this.$t('substitutionStatus.active'),
        }
      )

      // Unir las lineas con saltos de linea para mostrar un tooltip multilinea
      const message = `${tooltipSubstituteButtonLine1}<br>${tooltipSubstituteButtonLine2}<br>${tooltipSubstituteButtonLine3}<br>${tooltipSubstituteButtonLine4}`
      return message
    },
    toggleSelection(user) {
      this.selectedUser = this.selectedUser?.id === user?.id ? 'unselect' : user
      this.$emit('setAbsentUser', this.selectedUser)
    },
  },
}
</script>

<style scoped>
.text-container {
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: normal;
}

::v-deep .highlight--custom {
  border-radius: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 2px;
  padding-right: 4px;
}

.btn-substitute::before {
  opacity: 0.19 !important; /* Mantiene el efecto hover, asi el usuario percibe la silueta del boton */
}

.btn-substitute:hover::before {
  opacity: 0.3 !important; /* Aumenta la opacidad al hacer hover */
}

.selected-btn-simple {
  border: 3px solid var(--v-primary-darken2) !important;
}

.selected-btn {
  /*background-color: orange !important;*/
  background-color: green !important;
  color: white !important;
  /*background-color: var(--v-warningLow-base) !important;
  border-color: var(--v-warningLow-base) !important;*/
}

/* Sobreescribe la clase de Vuetify solo cuando el botón está seleccionado */
.selected-btn.primary--text {
  color: white !important;
}
</style>
