<template>
  <v-card
    data-v-step="6"
    class="mx-auto"
    outlined
    height="450"
    style="overflow-y: auto"
  >
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="headline mb-1">
          {{ $t('credentials') }}
        </v-list-item-title>
        <v-list-item-subtitle>{{ $t('managePassword') }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-form
      ref="changePasswordForm"
      v-model="validChangePassword"
      lazy-validation
    >
      <v-list-item>
        <v-container>
          <v-row class="pa-0 ma-0">
            <v-col
              v-if="hasExternalsAuths"
              class="pa-0 ma-0"
              cols="12"
              md="12"
              lg="12"
            >
              <v-switch
                v-model="externalAuthSwitch"
                @change="changeExternalAuth"
                :label="$t('externalAuth.title')"
                :disabled="isExternalAuthRequired && externalAuthSwitch"
                class="body-1 text-end pa-0 ma-0"
                id="external-auth"
              >
              </v-switch>
            </v-col>
            <v-col
              v-if="hasExternalsAuths && externalAuthSwitch"
              class="pa-0 mt-4"
              cols="12"
              md="12"
              lg="12"
            >
              <v-select
                prepend-icon="mdi-badge-account-horizontal"
                v-model="selectedExternalAuth"
                item-text="name"
                :items="externalsAuths"
                :label="$t('externalAuth.title')"
                dense
                return-object
                :disabled="loading"
                @change="changeExternalAuth"
              >
              </v-select>
            </v-col>
            <v-col
              v-if="hasExternalsAuths && externalAuthSwitch"
              class="pa-0 mt-16"
              cols="12"
              md="12"
            >
              <v-btn
                block
                :disabled="loading"
                :loading="loading"
                class="primary secondary--text"
                @click="updateExternalAuth"
              >
                {{ $t('updateData') }}
                <template v-slot:loader>
                  <v-progress-circular indeterminate size="20" width="2" />
                  <span class="ml-2">{{ $t('updatingUser') }}</span>
                </template>
              </v-btn>
            </v-col>
          </v-row>

          <v-row
            class="pa-0 ma-0"
            v-if="!(hasExternalsAuths && externalAuthSwitch)"
          >
            <v-col class="pa-0 ma-0" cols="12" md="12">
              <v-text-field
                v-model="currentPassword"
                :rules="passwordRules"
                :disabled="disableCurrentPassword"
                :label="$t('currentPassword')"
                :type="showCurrentPassword ? 'text' : 'password'"
                autocomplete="on"
                :append-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showCurrentPassword = !showCurrentPassword"
                prepend-icon="mdi-form-textbox-password"
              ></v-text-field>
            </v-col>
            <v-col class="pa-0 ma-0" cols="12" md="12">
              <v-text-field
                v-model="newPassword"
                :rules="newPassRules"
                :label="$t('newPassword')"
                :type="showNewPassword ? 'text' : 'password'"
                autocomplete="on"
                :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :hint="$t('passwordHint')"
                @click:append="showNewPassword = !showNewPassword"
                prepend-icon="mdi-form-textbox-password"
              >
              </v-text-field>
            </v-col>

            <v-col class="pa-0 ma-0" cols="12" md="12">
              <v-text-field
                v-model="confirmPassword"
                :rules="repeatNewPassRules"
                :label="$t('confirmPassword')"
                :type="showRepeatNewPassword ? 'text' : 'password'"
                autocomplete="on"
                :append-icon="showRepeatNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showRepeatNewPassword = !showRepeatNewPassword"
                prepend-icon="mdi-form-textbox-password"
              >
              </v-text-field>
            </v-col>
            <v-col class="pa-0 mt-6" cols="12" md="12">
              <v-btn
                block
                :disabled="loading"
                :loading="loading"
                class="primary secondary--text"
                @click="updatePassword"
              >
                {{ $t('changePassword') }}
                <template v-slot:loader>
                  <v-progress-circular indeterminate size="20" width="2" />
                  <span class="ml-2">{{ $t('updatingPassword') }}</span>
                </template>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-list-item>
    </v-form>
  </v-card>
</template>

<script>
import { Auth, Doc } from '@/firebase-exports'
import { updateUserPassword, updateUserInfo } from '@/services/user-service'
import rules from '@/utils/rules'
import getErrorText from '@/utils/get-error-text'
import { mapMutations, mapActions, mapGetters } from 'vuex'

export default {
  props: {
    externalsAuths: Array,
  },
  async beforeMount() {
    this.user = { ...this.$store.state.user }
    this.userModified = { ...this.user }
    if (!this.user) return this.setNotFound(true)

    this.externalAuth = this.user.externalAuth ?? {}
    this.externalAuthSwitch = this.externalAuth?.allowed ?? false
    // Timeout para que cargue la subcolección de autenticaciones externas.
    await new Promise((r) => setTimeout(r, 200))
    this.selectedExternalAuth = this.externalsAuths.find(
      (externalAuth) => externalAuth.id === this.externalAuth?.reference?.id
    )
  },
  data() {
    return {
      loading: false,
      showCurrentPassword: false,
      passwordRules: [rules.required],
      showNewPassword: false,
      newPassRules: [
        rules.required,
        rules.passwordLength,
        rules.passwordStrong,
      ],
      showRepeatNewPassword: false,

      validChangePassword: true,
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',

      user: {},
      userModified: {},
      externalAuth: {},
      externalAuthSwitch: false,
      selectedExternalAuth: undefined,
      disableCurrentPassword: false,
    }
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    ...mapActions(['logout']),
    async updatePassword() {
      if (!this.$refs.changePasswordForm.validate()) return

      this.loading = true
      try {
        const user = Auth.getAuth().currentUser

        try {
          await Auth.reauthenticateWithCredential(
            user,
            Auth.EmailAuthProvider.credential(
              this.$store.state.user.email,
              this.currentPassword
            )
          )

          await Auth.updatePassword(user, this.newPassword)
        } catch (err) {
          if (err.message.includes('auth/multi-factor-auth-required')) {
            try {
              await updateUserPassword({
                newPassword: this.newPassword,
              })

              setTimeout(async () => {
                await this.logout()
                this.$router.push({ name: 'LoginView' })
              }, 2000)
            } catch (err) {
              const message = getErrorText(err.message)
              this.setSnackbar({ position: 'top', type: 'error', message })
            }
          } else {
            throw new Error(err.message)
          }
        }

        if (this.hasExternalsAuths && this.externalAuth.token) {
          this.externalAuth.allowed = false
          delete this.externalAuth.path
          delete this.externalAuth.reference
          delete this.externalAuth.token
          // Actualizamos los datos del usuario relativos a la autenticación externa.
          await updateUserInfo(this.user.id, {
            externalAuth: this.externalAuth,
            singleSignOn: false,
          })
        }

        const message = this.$t('updatingPasswordSuccess')
        this.setSnackbar({ position: 'top', type: 'success', message })

        this.$refs.changePasswordForm.reset()
      } catch (err) {
        const message = getErrorText(err.message)
        this.setSnackbar({ position: 'top', type: 'error', message })
      }

      this.loading = false
    },
    async updateExternalAuth() {
      this.loading = true
      console.log('Updating External Auth')

      try {
        const hasGenerateToken = this.externalAuth.allowed
        // Actualizamos contraseña
        if (hasGenerateToken) {
          const token = await updateUserPassword({
            newPassword: null,
            email: this.user.email,
            hasGeneratePassword: hasGenerateToken,
          })
          this.externalAuth.token = token.data
          if (this.externalAuth.path) {
            this.externalAuth.reference = Doc(this.externalAuth.path)
            delete this.externalAuth.path
          }
          // Actualizamos los datos del usuario relativos a la autenticación externa.
          await updateUserInfo(this.user.id, {
            externalAuth: this.externalAuth,
            singleSignOn: true,
          })
        }
      } catch (err) {
        const message = getErrorText(err.message)
        this.setSnackbar({ position: 'top', type: 'error', message })
      }
      this.loading = false
    },
    changeExternalAuth() {
      if (
        this.externalAuth.allowed &&
        this.externalAuth.token &&
        !this.externalAuthSwitch
      ) {
        this.disableCurrentPassword = true
        const token = JSON.parse(
          Buffer.from(this.externalAuth.token, 'base64').toString('utf8')
        )
        this.currentPassword = token.password
      } else {
        this.disableCurrentPassword = false
        this.currentPassword = ''
      }
      this.externalAuth.allowed = this.externalAuthSwitch
      this.externalAuth.path = this.selectedExternalAuth?.path ?? undefined
    },
  },
  computed: {
    ...mapGetters(['hasExternalsAuths', 'isExternalAuthRequired']),
    repeatNewPassRules() {
      return [rules.required, rules.passwordNotRepeated(this.newPassword)]
    },
  },
}
</script>
