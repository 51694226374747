<template>
  <v-container fill-height fluid class="pa-0">
    <v-row no-gutters style="height: 100%">
      <v-col :cols="isMobile ? 12 : 3">
        <v-img
          :gradient="gradient"
          height="100%"
          width="100%"
          src="@/assets/login-wallpaper.jpg"
          content-class="loginImage"
        >
          <v-container fill-height fluid class="pa-5">
            <v-row align="center" justify="center">
              <v-col cols="12" align="center" justify="center">
                <v-img
                  contain
                  :src="getImageSource"
                  transition="fade-transition"
                  max-width="200"
                />
              </v-col>
              <v-col
                cols="12"
                align="center"
                justify="center"
                class="font-weight-light white--text text-h5"
              >
                {{ $t('loginTitle') }}
              </v-col>
              <v-col
                cols="12"
                align="center"
                justify="center"
                class="font-weight-light font-italic white--text text-body-2"
              >
                {{ $t('loginSubtitle') }}
              </v-col>

              <MicrosoftAuth
                v-if="isMobile && isMicrosoftAuth"
                :usernameProp="username"
                @login-change="changeLoginType"
              />
              <LoginUser
                v-else-if="isMobile"
                :usernameProp="username"
                @login-change="changeLoginType"
              />
            </v-row>
          </v-container>
        </v-img>
      </v-col>
      <v-col v-if="!isMobile" cols="9" align="center" justify="center">
        <MicrosoftAuth
          v-if="isMicrosoftAuth"
          :usernameProp="username"
          @login-change="changeLoginType"
        />
        <LoginUser
          v-else
          :usernameProp="username"
          @login-change="changeLoginType"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LoginUser from '@/components/login/LoginUser'
import MicrosoftAuth from '@/components/login/MicrosoftAuth'
import { mapGetters } from 'vuex'
import { setBrand, hexToRgbaConv } from '@/utils/brand-utils'

export default {
  components: {
    LoginUser,
    MicrosoftAuth,
  },
  data() {
    return {
      isMicrosoftAuth: false,
      username: '',
    }
  },
  methods: {
    changeLoginType(type, username) {
      this.isMicrosoftAuth = type === 'Microsoft' ? true : false
      this.username = username
    },
  },
  computed: {
    ...mapGetters(['isMobile']),
    getImageSource() {
      if (this.$store.state.brand?.appLogo?.startsWith('http')) {
        return this.$store.state.brand.appLogo
      } else {
        return require('@/assets/app-logo.png')
      }
    },
    gradient() {
      if (this.$store.state.brand?.primaryColor) {
        const rgba = hexToRgbaConv(this.$store.state.brand.primaryColor, 0.9)
        return `to top right, ${rgba}, ${rgba}`
      } else {
        return 'to top right, rgba(29,158,164,.9), rgba(29,158,164,.9)'
      }
    },
  },
  mounted() {
    if (this.$route.query.brand)
      setBrand(
        JSON.parse(
          Buffer.from(this.$route.query.brand, 'base64').toString('utf8')
        )
      )
  },
}
</script>

<style lang="scss">
.loginImage {
  width: 100% !important;
}
</style>
