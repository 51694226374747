class AzureService {
  constructor(clientId, authority) {
    this.msalConfig = {
      auth: {
        clientId: clientId,
        authority: authority,
      },
      cache: {
        // cacheLocation: 'localStorage',
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
      },
    }
    this.accessToken = ''
  }

  setAccessToken(token) {
    this.accessToken = token
  }

  getAccessToken() {
    return this.accessToken
  }

  getMsalConfig() {
    return this.msalConfig
  }
}

export default AzureService
