<template>
  <div>
    <v-tour :name="name" :steps="steps">
      <template slot-scope="tour">
        <transition name="fade">
          <v-step
            class="pa-0"
            v-if="tour.steps[tour.currentStep]"
            :key="tour.currentStep"
            :step="tour.steps[tour.currentStep]"
            :stop="tour.stop"
            :skip="tour.skip"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
            :highlight="true"
            :enableScrolling="false"
          >
            <div class="primary pa-2" slot="header">
              <h2>{{ $t(tour.steps[tour.currentStep].header.title) }}</h2>
            </div>

            <div class="pt-3 pr-3 pl-3" slot="content">
              <img
                contain
                v-if="tour.steps[tour.currentStep].showLogo"
                :src="getImageSource"
                width="150"
              />

              <p class="body-1">
                {{ $t(tour.steps[tour.currentStep].content) }}
              </p>
            </div>

            <div class="pl-5 pr-5 pb-5" slot="actions">
              <v-container class="pa-0" fill-height fluid>
                <v-row class="pa-0 ma-0" justify="center">
                  <v-col :cols="6" class="ma-0 pa-1" v-if="!tour.isFirst">
                    <v-btn
                      class="ma-0 white--text"
                      outlined
                      block
                      @click="myCustomPrevStep(tour)"
                    >
                      {{ $t('tour.previousStep') }}
                    </v-btn>
                  </v-col>
                  <v-col
                    class="ma-0 pa-1"
                    :cols="tour.isFirst ? 12 : 6"
                    v-if="!tour.isLast"
                  >
                    <v-btn
                      block
                      outlined
                      @click="myCustomNextStep(tour)"
                      class="ma-0 primary white--text"
                    >
                      {{ $t('tour.nextStep') }}
                    </v-btn>
                  </v-col>
                  <v-col class="ma-0 pa-1" cols="12" v-if="!tour.isLast">
                    <v-btn
                      block
                      outlined
                      @click="tour.skip"
                      class="warning ma-0 white--text"
                    >
                      {{ $t('tour.skipTour') }}
                    </v-btn>
                  </v-col>
                  <v-col
                    class="ma-0 pa-1"
                    cols="12"
                    v-if="tour.isFirst || tour.isLast"
                  >
                    <v-btn
                      block
                      outlined
                      @click="tourCustomFinish"
                      class="ma-0 white--text"
                    >
                      {{ $t('tour.finishTour') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-step>
        </transition>
      </template>
    </v-tour>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'my-tour',
  props: {
    stepsProp: { type: Array, required: true },
    autoStart: { type: Boolean, default: false },
    name: { type: String, required: true },
    finishFunction: Function,
  },
  mounted() {
    this.steps = this.stepsProp
    if (this.autoStart) {
      this.startTour()
    }
  },
  computed: {
    ...mapGetters(['isSubstituteMode']),
    getImageSource() {
      if (this.$store.state.brand?.tourLogo?.startsWith('http')) {
        return this.$store.state.brand.tourLogo
      } else {
        return require('@/assets/tour-logo.png')
      }
    },
  },
  data() {
    return {
      steps: [{}],
    }
  },
  methods: {
    startTour() {
      this.$tours[this.name].start()
    },
    async myCustomNextStep(step) {
      if (this.steps[step.currentStep].destPat) {
        this.$router.push({ name: this.steps[step.currentStep].destPat })
        await new Promise((resolve) => setTimeout(resolve, 250))
      }
      step.nextStep()
    },
    async myCustomPrevStep(step) {
      if (this.steps[step.currentStep].fromPath) {
        this.$router.push({ name: this.steps[step.currentStep].fromPath })
        await new Promise((resolve) => setTimeout(resolve, 250))
      }
      step.previousStep()
    },
    async tourCustomFinish() {
      this.finishFunction()
      this.$tours[this.name].finish()
    },
  },
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
