import {
  Firestore,
  ExternalsAuthsCollection,
  ExternalAuthDoc,
} from '@/firebase-exports'
import store from '@/store/index'

/**
 * Returns a list of the externals auths.
 * @returns List of the externals auths.
 */
export async function listExternalsAuths(userIdParam = undefined) {
  const userId = userIdParam
    ? userIdParam
    : store.state.user.parentRef
    ? store.state.user.parentRef.id
    : store.state.user.id

  const querySnapshot = await Firestore.getDocs(
    ExternalsAuthsCollection(userId)
  )
  return querySnapshot.docs.map((doc) => ({
    id: doc.id,
    path: doc.ref.path,
    reference: doc.ref,
    ...doc.data(),
  }))
}

/**
 * Gets an external authentication of a user by id
 * @param {String} userId User ID
 * @param {string} externalAuthId External Auth ID.
 * @returns The external user authentication corresponding to the ID.
 */
export async function getExternalAuth(userId, externalAuthId) {
  let externalAuth = undefined
  const docSnap = await Firestore.getDoc(
    ExternalAuthDoc(userId, externalAuthId)
  )
  if (docSnap._document === null) return false

  const document = docSnap.data()
  if (document) externalAuth = { externalAuthId, ...document }
  return externalAuth
}

export async function logoutExternalAuth() {
  if (store.state.msalInstance == null) return

  try {
    const msalInstance = store.state.msalInstance
    const email = store.state.userSubstitute
      ? store.state.user.userSubstitute.email
      : store.state.user.email
    const account = msalInstance.getAccountByUsername(email)
    console.log('logout - account', account)
    if (account) {
      await msalInstance
        .logoutPopup({ account: account })
        .then(() => {
          const myAccounts = msalInstance.getAllAccounts()
          console.log('logout - after - accounts', myAccounts)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  } catch (err) {
    console.log(err)
  }
}

export function getExternalsAuthsSubscription(callback) {
  const userId = store.state.user.parentRef
    ? store.state.user.parentRef.id
    : store.state.user.id

  var externalsAuthsUnsubcribe = Firestore.onSnapshot(
    Firestore.query(
      ExternalsAuthsCollection(userId),
      Firestore.orderBy('name')
    ),
    (snapShot) => {
      let externalsAuths = snapShot.docs.map((doc) => ({
        id: doc.id,
        path: doc.ref.path,
        ...doc.data(),
      }))
      callback(externalsAuths)
    },
    (error) => {
      throw error
    }
  )
  return externalsAuthsUnsubcribe
}
