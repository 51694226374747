import {
  CreateOperator,
  CreateOperatorBulk,
  DisableUserOperator,
  Firestore,
  UpdateOperatorEmail,
  UpdateOperatorPassword,
  UpdateOperatorMultifactor,
  OperatorDoc,
  UserOperatorsQuery,
  GetDocFromReference,
  Doc,
  GroupDoc,
  UsersDoc,
  SendMailOperatorAbsence,
} from '@/firebase-exports'
import { getUserInfo } from './user-service'
import store from '@/store/index'
import { getUserTrace } from '@/services/trace-service'
const lodash = require('lodash')

/**
 * Obtiene los operadores asociados a un usuario.
 *
 * @returns {Promise<Array<Object>>} Una promesa que se resuelve con un array de operadores.
 */
export async function getUserOperators() {
  const userId = store.state.user.parentRef
    ? store.state.user.parentRef.id
    : store.state.user.id
  const docsSnap = await Firestore.getDocs(UserOperatorsQuery(userId))
  return (
    docsSnap.docs
      .map((doc) => {
        return { id: doc.id, ...doc.data() }
      })
      //.sort((a, b) => a.name.localeCompare(b.name))
      // mejora: si los nombres son iguales, compara por surname
      .sort((a, b) => {
        const nameComparison = a.name.localeCompare(b.name)
        return nameComparison !== 0
          ? nameComparison
          : a.surname.localeCompare(b.surname)
      })
  )
}

/**
 * Gets the operators of the current user stored in an object
 * where the key is the id and the value is the document (id included).
 * @param {string} userId  Id of the main user to get their operators
 * @param {Boolean} includeParent Ability to also add parent user to object dict (default=false)
 * @returns Operators of a user but stored in an object.
 */
export async function getUserOperatorsDict(userId, includeParent = false) {
  const docsSnap = await Firestore.getDocs(UserOperatorsQuery(userId))

  const operators = {}

  docsSnap.docs.forEach((doc) => {
    operators[doc.id] = { id: doc.id, ...doc.data() }
  })

  if (includeParent) operators[userId] = await getUserInfo(userId)

  return operators
}

/**
 * Crea una subscripción a los operadores de un usuario, actualizando el contexto con los operadores en tiempo real.
 *
 * @param {string} userId - El ID del usuario.
 * @param {Object} context - Contexto del componente donde se realiza la subscripción.
 * @returns {Function} Función para cancelar la subscripción.
 */
export function getOperatorsSubscriptionSimplified(userId, context) {
  var operatorsUnsubscribe = Firestore.onSnapshot(
    UserOperatorsQuery(userId),
    (snapShot) => {
      context.operators = snapShot.docs
        .map((doc) => {
          return { id: doc.id, ...doc.data() }
        })
        .sort((a, b) => a.name.localeCompare(b.name))
    },
    (error) => {
      throw error
    }
  )

  return operatorsUnsubscribe
}

/**
 * Crea una subscripción a los operadores de un usuario, incluyendo los grupos a los que pertenecen, actualizando el estado en tiempo real.
 *
 * @param {string} userId - El ID del usuario.
 * @returns {Function} Función para cancelar la subscripción.
 */
export function getOperatorsSubscription(userId) {
  let groupsUser = store.state.user.groups?.length
    ? lodash.cloneDeep(store.state.user.groups)
    : undefined

  var operatorsUnsubscribe = Firestore.onSnapshot(
    UserOperatorsQuery(userId),
    (snapShot) => {
      let operators = snapShot.docs.map((doc) => {
        if (!groupsUser) return { id: doc.id, ...doc.data() }
        else if (
          groupsUser &&
          doc.data().groupsRef?.some((group) => group.id === groupsUser[0].id)
        )
          return { id: doc.id, ...doc.data() }
        else return undefined
      })

      // Si pertenece a grupo filtramos
      const finalOperators = (
        groupsUser
          ? operators.filter((operator) => operator !== undefined)
          : operators
      ).sort((a, b) => a.name.localeCompare(b.name))

      // Guardamos en el store
      store.commit('setManageOperators', finalOperators)
    },
    (error) => {
      throw error
    }
  )

  store.commit('setManageOperatorsUnsubscribe', operatorsUnsubscribe)
  return operatorsUnsubscribe
}

export function getOperatorsSubscriptionWithTraces(
  userId,
  callback,
  filteredByAction,
  includeParent = false
) {
  var operatorsUnsubscribe = Firestore.onSnapshot(
    UserOperatorsQuery(userId),
    async (snapShot) => {
      let operators = snapShot.docs
        .map((doc) => {
          return { id: doc.id, ...doc.data() }
        })
        .sort((a, b) => a.name.localeCompare(b.name))

      //console.log('includeParent:' + includeParent)
      if (includeParent) operators.push(await getUserInfo(userId))

      for (let index = 0; index < operators.length; index++) {
        let traces = await getUserTrace(operators[index].id)
        let operatorWithTraces =
          filteredByAction !== undefined && filteredByAction !== ''
            ? traces.filter((trace) => trace.actionType === filteredByAction)
            : traces
        operators[index].traces = operatorWithTraces
        //console.log('recuperador de trazas', operators[index].traces, index)
      }
      callback(operators)
    },
    (error) => {
      throw error
    }
  )

  return operatorsUnsubscribe
}

/**
 * Obtiene los filtros asociados a un operador. Itera sobre las referencias a los filtros
 * del operador y recupera los detalles de cada filtro, ya sea desde el estado global de la
 * tienda o directamente desde la base de datos si no están en el estado global.
 *
 * @param {Object} operator - El operador para el cual se recuperarán los filtros.
 * @returns {Promise<Array<Object>>} Una promesa que se resuelve con un array de filtros asociados al operador.
 */
export async function getOperatorFilters(operator) {
  const filters = []

  // Iteramos sobre las referencias a los filters
  for (let i = 0; i < operator.filtersRef?.length; i++) {
    const filterRef = operator.filtersRef[i]

    let filter = {}
    if (!store.state.filters) filter = await GetDocFromReference(filterRef)
    else filter = store.state.filters[filterRef.id]

    filters.push(filter)
  }

  return filters
}

/**
 * Crea un nuevo operador para un usuario padre.
 *
 * @param {string} userId - El ID del usuario.
 * @param {Object} operatorData - Los datos del operador a crear.
 */
export async function createUserOperator(userId, operatorData) {
  // Llamando a la function que añade operadores
  const { data } = store.getters.isDemoEnv
    ? await CreateOperator({
        userId,
        ...operatorData,
        env: 'demo',
      })
    : await CreateOperator({
        userId,
        ...operatorData,
      })

  // Añadimos al grupo al nuevo operador si es agregado por un usuario de un grupo
  const groupsRef = store.getters.isInGroupUser
    ? [GroupDoc(userId, store.state.user.groupsRef[0].id)]
    : undefined

  if (groupsRef) Firestore.updateDoc(UsersDoc(data.uid), { groupsRef })
}

/**
 * Crea operadores en masa para un usuario padre a partir de un archivo.
 *
 * @param {string} userId - El ID del usuario.
 * @param {File} file - Archivo que contiene los datos de los operadores a crear.
 * @param {Array<string>} groupsIds - Los IDs de los grupos a los que pertenecerán los operadores.
 * @returns {Promise<any>} Una promesa que se resuelve con la respuesta del proceso de creación.
 */
export async function createUserOperatorBulk(userId, file, groupsIds) {
  return await CreateOperatorBulk({ userId, file, groupsIds })
}

/**
 * Actualiza los datos de un operador específico.
 *
 * @param {string} operatorId - El ID del operador a actualizar.
 * @param {Object} operatorData - Los nuevos datos del operador.
 */
export async function updateUserOperator(operatorId, operatorData) {
  const userId = store.state.user.parentRef
    ? store.state.user.parentRef.id
    : store.state.user.id

  // Adapting the admin roles
  if (!operatorData.adminPermissions)
    operatorData.adminRoles = Firestore.deleteField()

  // Adapting the filters ref
  operatorData.filtersPath.forEach((path) => {
    if (!operatorData.filtersRef) operatorData.filtersRef = [Doc(path)]
    else operatorData.filtersRef.push(Doc(path))
  })
  if (!operatorData.filtersRef) operatorData.filtersRef = []
  delete operatorData.filtersPath

  // Adapting the cifsRef
  operatorData.cifsPath.forEach((path) => {
    if (!operatorData.cifsRef) operatorData.cifsRef = [Doc(path)]
    else operatorData.cifsRef.push(Doc(path))
  })
  if (!operatorData.cifsRef) operatorData.cifsRef = []
  delete operatorData.cifsPath

  // Adapting the GroupsRef
  operatorData.groupsIds.forEach((groupId) => {
    if (!operatorData.groupsRef)
      operatorData.groupsRef = [GroupDoc(userId, groupId)]
    else operatorData.groupsRef.push(GroupDoc(userId, groupId))
  })
  if (!operatorData.groupsRef) operatorData.groupsRef = []
  delete operatorData.groupsIds

  // External authentication
  if (operatorData?.externalAuth?.allowed) {
    if (operatorData.externalAuth.path) {
      operatorData.externalAuth.reference = Doc(operatorData.externalAuth.path)
      delete operatorData.externalAuth.path
    }
    operatorData.singleSignOn = true
  } else {
    operatorData.externalAuth.allowed = false
    delete operatorData?.externalAuth?.path
    delete operatorData?.externalAuth?.reference
    delete operatorData?.externalAuth?.token
    operatorData.singleSignOn = false
  }

  await Firestore.updateDoc(OperatorDoc(operatorId), operatorData)
}

/**
 * Actualiza el correo electrónico de un operador.
 *
 * @param {string} operatorId - El ID del operador.
 * @param {string} newEmail - El nuevo correo electrónico.
 */
export async function updateUserOperatorEmail(operatorId, newEmail) {
  await UpdateOperatorEmail({
    operatorId,
    newEmail,
  })
}

/**
 * Actualiza la contraseña de un operador.
 *
 * @param {string} operatorId - El ID del operador.
 * @param {string} newPassword - La nueva contraseña.
 */
export async function updateUserOperatorPassword(
  operatorId,
  newPassword,
  email,
  hasGeneratePassword
) {
  return await UpdateOperatorPassword({
    operatorId,
    newPassword,
    email,
    hasGeneratePassword,
  })
}

/**
 * Actualiza la configuración de autenticación multifactor de un operador.
 *
 * @param {string} operatorId - El ID del operador.
 * @param {boolean} new2FA - El nuevo estado de autenticación multifactor.
 */
export async function updateUserOperatorMultifactor(operatorId, new2FA) {
  await UpdateOperatorMultifactor({
    operatorId,
    new2FA,
  })
}

/**
 * Habilita o deshabilita un operador.
 *
 * @param {string} operatorId - El ID del operador.
 * @param {boolean} disabled - Estado de habilitación del operador.
 */
export async function disableOperator(operatorId, disabled) {
  await DisableUserOperator({ operatorId, disabled })
}

/**
 * Checks if a given operator has an specific filter.
 * @param {Object} operator Operator object.
 * @param {Array} selectedFilters Path of the filter to check.
 * @returns Boolean value checking if the operator has all the filters.
 */
export function operatorHasFilterById(operator, selectedFilters = [], filters) {
  const noFilter = filters.find((filter) => filter.name === 'noFilter')

  // Checking of the operator has the "no filter" filter
  if (operator.filtersRef.some((filterRef) => filterRef.id === noFilter.id))
    return true

  return selectedFilters.every((sf) =>
    operator.filtersRef.some((fr) => fr.path === sf)
  )
}

/**
 * Checks if a given operator has a cif
 * @param {Object} operator Operator document.
 * @param {Array} cifs Array of cifs (strings like A1234...).
 * @returns Boolean value that represents if the operator has an specific cif.
 */
export function operatorHasCifs(operator, cifs) {
  if (!cifs) return true

  // Checking that the operator has every cif
  return cifs.every((cif) =>
    Object.keys(operator.cifsPermissions).includes(cif)
  )
}

/**
 * Checks if a given operator has a cif by checking the id of this cifs.
 * @param {Object} operator Operator document.
 * @param {Array} cifs Array of cifs' ids.
 * @returns Boolean value that represents if the operator has an specific cif.
 */
export function operatorHasCifsById(operator, cifs) {
  if (!cifs) return true

  const cifsIds = operator.cifsRef.map((ref) => ref.id)

  // Checking that the operator has every cif
  return cifs.every((id) => cifsIds.includes(id)) || operator.adminPermissions
}

/**
 * Maps operators and groups giving an object where the key is the group id and
 * the value are the operators that belong to that group.
 * @param {Array} operators Array of operators to map with their groups.
 * @returns Object with group ids as keys and a list of operators as values.
 */
export function mapOperatorsAndGroups(operators) {
  if (!operators) return {}

  const mapped = {}

  for (const operator of operators) {
    if (!operator.groupsRef) continue

    for (const group of operator.groupsRef) {
      if (!mapped[group.id]) mapped[group.id] = [operator]
      else mapped[group.id].push(operator)
    }
  }

  return mapped
}

export async function getOperator(operatorId) {
  const docSnap = await Firestore.getDoc(UsersDoc(operatorId))
  if (!docSnap.exists() || !docSnap.data()) {
    return
  }
  return docSnap.data()
}

/**
 * Crea las ausencias de un operador
 *
 * @param {string} operatorId - El Id del operador al que actualizar una ausencia
 * @param {Object} absence - Los datos de la ausencia
 */
export async function addOperatorAbsence(operatorId, absence) {
  // Obtiene los datos del documento del operador a actualizar.
  var operatorData = await getOperator(operatorId)

  // construye el objeto a insertar en el array de ausencias,
  // obtiene la referencia al sustituto segun el entorno
  const newAbsence = {
    initialDate: absence.initialDate,
    finalDate: absence.finalDate,
    substituteRef: store.getters.isDemoEnv
      ? Doc(`usersDemo/${absence.substituteId}`)
      : Doc(`users/${absence.substituteId}`),
    createdByRef: store.getters.isDemoEnv
      ? Doc(`usersDemo/${absence.createdById}`)
      : Doc(`users/${absence.createdById}`),
  }
  // crea el array si no existe, o añade un nuevo elemento si ya existe
  if (!operatorData.absences) operatorData.absences = [newAbsence]
  else operatorData.absences.push(newAbsence)

  await Firestore.updateDoc(OperatorDoc(operatorId), operatorData)

  // Parte 2: Añadir al array del sustituto y actualizarlo
  // Obtiene los datos del documento del operador sustituto a actualizar.
  var substituteData = await getOperator(absence.substituteId)

  // construye el objeto a insertar en el array de ausencias,
  const newSubstitution = {
    initialDate: absence.initialDate,
    finalDate: absence.finalDate,
    substituteRef: store.getters.isDemoEnv
      ? Doc(`usersDemo/${operatorId}`)
      : Doc(`users/${operatorId}`),
    createdByRef: store.getters.isDemoEnv
      ? Doc(`usersDemo/${absence.createdById}`)
      : Doc(`users/${absence.createdById}`),
  }

  // crea el array si no existe, o añade un nuevo elemento si ya existe
  if (!substituteData.substitutions)
    substituteData.substitutions = [newSubstitution]
  else substituteData.substitutions.push(newSubstitution)

  await Firestore.updateDoc(OperatorDoc(absence.substituteId), substituteData)
}

/**
 * Borra una ausencia de un operador
 *
 * @param {string} operatorId - El Id del operador al que actualizar una ausencia
 * @param {Object} absence - Los datos de la ausencia
 */
export async function deleteOperatorAbsence(operatorId, absence) {
  if (absence) {
    // Obtiene los datos del documento del operador a actualizar.
    var operatorData = await getOperator(operatorId)

    if (operatorData && operatorData.absences) {
      const index = operatorData.absences.findIndex((obj) =>
        compareAbsences(obj, absence)
      )

      if (index !== -1) {
        operatorData.absences.splice(index, 1)

        if (!operatorData.absences || operatorData.absences.length === 0)
          operatorData.absences = Firestore.deleteField()

        await Firestore.updateDoc(OperatorDoc(operatorId), operatorData)
      }
    }

    // Parte 2: Eliminar del array del sustituto y actualizarlo
    // Obtiene los datos del documento del operador sustituto a actualizar.
    var substituteData = await getOperator(absence?.substituteRef.id)

    if (substituteData && substituteData.substitutions) {
      const index2 = substituteData.substitutions.findIndex((obj) =>
        compareSubstitutions(obj, absence, operatorId)
      )

      if (index2 !== -1) {
        substituteData.substitutions.splice(index2, 1)
        if (
          !substituteData.substitutions ||
          substituteData.substitutions.length === 0
        )
          substituteData.substitutions = Firestore.deleteField()

        await Firestore.updateDoc(
          OperatorDoc(absence.substituteRef.id),
          substituteData
        )
      }
    }
  }
}

/**
 * Envio de correos informativos de ausencias de un operador.
 *
 * @param {string} operatorId - El ID del operador.
 * @param {Object} absenceData - datos de la ausencia.
 */
export async function sendMailOperatorAbsence(operatorId, absenceData) {
  await SendMailOperatorAbsence({ operatorId, absenceData })
}

/**
 * Obtiene la/las sustitucion/es de un operador que está sustituyendo a otro/s en el día de hoy.
 *
 * @param {Object} operator - El operador para el cual se recuperarán las sustituciones.
 * @returns {Promise<Array<Object>>} Una promesa que se resuelve con un array de sustituciones del operador.
 */
export async function getOperatorSubstitutionsForToday(operator) {
  if (!operator.substitutions || !operator.substitutions.length) return []

  let substitutionsToday = []
  const matchingItems = activeSubstitutions(operator.substitutions)

  for (let i = 0; i < matchingItems.length; i++) {
    const matchingItem = matchingItems[i]
    const userDoc = await getOperator(matchingItem.substituteRef.id)
    const substitution = {
      substituteRef: matchingItem.substituteRef,
      initialDate: formatDate(matchingItem.initialDate),
      finalDate: formatDate(matchingItem.finalDate),
      fullname: `${userDoc.name || ''} ${userDoc.surname || ''}`,
      email: `${userDoc.email || ''}`,
    }
    substitutionsToday.push(substitution)
  }

  return substitutionsToday
}

// Método para comparar dos objetos absence
function compareAbsences(item1, item2) {
  return (
    item1.initialDate === item2.initialDate &&
    item1.finalDate === item2.finalDate &&
    item1.substituteRef.id === item2.substituteRef.id
  )
}

function compareSubstitutions(substitution, absence, operatorId) {
  return (
    substitution.initialDate === absence.initialDate &&
    substitution.finalDate === absence.finalDate &&
    substitution.substituteRef.id === operatorId
  )
}

// Método para filtrar las sustituciones que esten activas hoy
function activeSubstitutions(items) {
  if (!items) return []
  const today = new Date().toISOString().slice(0, 10).replace(/-/g, '')
  const matchingItems = items.filter((item) => {
    const initialDate = new Date(item.initialDate)
      .toISOString()
      .slice(0, 10)
      .replace(/-/g, '')
    const finalDate = new Date(item.finalDate)
      .toISOString()
      .slice(0, 10)
      .replace(/-/g, '')
    return today >= initialDate && today <= finalDate
  })

  return matchingItems
}

// Recibe fecha string como aaaa-mm-dd y la devuelve como dd/mm/aaaa
// Nota: he sustituido el guion por la barra porque si no al mostrarlo en el banner se parten en dos palabras
function formatDate(dateString) {
  if (!dateString) return ''
  const [year, month, day] = dateString.split('-')
  return `${day}/${month}/${year}`
}
