<template>
  <div>
    <v-card class="mx-auto" outlined>
      <v-container>
        <v-row justify="space-between" align="center">
          <v-col cols="8" xl="auto" class="order-0">
            <v-row align="baseline" class="ml-1">
              <span class="headline">{{
                $t('operatorActions.title.absences')
              }}</span>
              <div class="mx-2" />
              <DialogButton
                section="absences"
                subsection="main"
                :title="$t('operatorActions.title.absences')"
                hideSubtitle
              />
            </v-row>
          </v-col>
          <v-col
            class="d-flex justify-end align-center order-1 order-xl-2"
            cols="4"
            xl="auto"
          >
            <v-tooltip bottom v-if="isAdminUser || isAdminAndCanManageUsers">
              <template
                v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }"
              >
                <v-btn
                  class="primary secondary--text ml-1 mt-1 mb-1"
                  fab
                  elevation="1"
                  small
                  v-bind="{ ...attrsTooltip }"
                  v-on="{ ...onTooltip }"
                  id="operator-absences-history"
                  @click.stop="openOperatorAbsenceHistoryDialog()"
                >
                  <v-icon>mdi-clipboard-text-clock</v-icon>
                </v-btn>
              </template>
              <div>
                {{ $t('operatorActions.title.history-absences') }}
              </div>
            </v-tooltip>

            <v-tooltip bottom v-if="isAdminUser || isAdminAndCanManageUsers">
              <template
                v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }"
              >
                <v-btn
                  class="primary secondary--text ml-1 mt-1 mb-1"
                  fab
                  elevation="1"
                  small
                  v-bind="{ ...attrsTooltip }"
                  v-on="{ ...onTooltip }"
                  :disabled="loading || phase1Condition"
                  @click.stop="openOperatorAbsenceDialog('create-absence', {})"
                  id="create-operator-absence"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <div>
                {{ $t('operatorActions.title.create-absence') }}
              </div>
            </v-tooltip>
          </v-col>
        </v-row>

        <v-row class="text-left mt-0 pt-0">
          <v-col cols="8" class="mt-0 pt-0 ml-4">
            <div class="mr-0 pr-0 body-1">
              {{ `${this.operator.name} ${this.operator.surname}` }}
            </div>
            <div class="body-2">
              {{ this.operator.email }}
            </div>
          </v-col>
        </v-row>
      </v-container>

      <v-divider></v-divider>
      <template>
        <v-data-table
          :loading="loading"
          :headers="computedHeaders"
          :items="operatorAbsences"
          :sort-by="['initialDate']"
          :items-per-page="5"
          :footer-props="{ 'items-per-page-options': [5, 10] }"
          class="elevation-1"
          :no-data-text="$t('emptyOperatorAbsences')"
        >
          <template v-slot:[`item.initialDate`]="{ item }">
            {{ formatDate(item.initialDate) }}
          </template>

          <template v-slot:[`item.finalDate`]="{ item }">
            {{ formatDate(item.finalDate) }}
          </template>

          <template v-slot:[`item.substituteName`]="{ item }">
            <span v-if="computedSubstitutes[item.substituteRef]">
              {{ computedSubstitutes[item.substituteRef].name }}
              {{ computedSubstitutes[item.substituteRef].surname }}
            </span>
            <span v-else>
              <span v-if="item.substituteRef">Leyendo...</span>
            </span>
          </template>

          <template v-slot:[`item.substituteMail`]="{ item }">
            <span v-if="computedSubstitutes[item.substituteRef]">
              {{ computedSubstitutes[item.substituteRef].email }}
            </span>
          </template>

          <template v-slot:[`item.deleteItem`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ma-1"
                  elevation="1"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  id="delete-operator-absence"
                  @click.stop="
                    openOperatorAbsenceDialog('cancel-absence', item)
                  "
                >
                  <v-icon> mdi-delete </v-icon>
                </v-btn>
              </template>
              <div>
                {{ $t('operatorActions.title.cancel-absence') }}
              </div>
            </v-tooltip>
          </template>
        </v-data-table>
      </template>
    </v-card>

    <OperatorAbsenceDialog
      v-if="showDialog"
      :action="actionDialog"
      :operator="operator"
      :absenceItem="absenceItem"
      :substituteCache="substituteCache"
      @closeOperatorAbsenceDialog="closeOperatorAbsenceDialog"
    />

    <OperatorAbsenceHistoryDialog
      v-if="showAbsenceHistoryDialog"
      :operator="operator"
      :operators="operators"
      :history="absencesTraces"
      @closeOperatorAbsenceHistoryDialog="closeOperatorAbsenceHistoryDialog"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import OperatorAbsenceDialog from '@/components/operators/OperatorAbsenceDialog'
import OperatorAbsenceHistoryDialog from '@/components/operators/OperatorAbsenceHistoryDialog'
import DialogButton from '@/components/documentation/buttons/DialogButton'
import { getOperator } from '@/services/operator-service'

export default {
  props: {
    operator: Object,
    operators: Array,
    substituteCache: Array,
    absencesTraces: Array,
  },
  components: {
    OperatorAbsenceDialog,
    OperatorAbsenceHistoryDialog,
    DialogButton,
  },
  data() {
    return {
      loading: false,
      showDialog: false,
      actionDialog: '',
      operatorAbsences: [],
      substitutes: [], // almacena los datos de cada uno de los sustitutos
      absenceItem: '',
      showAbsenceHistoryDialog: false,
    }
  },
  async mounted() {
    this.operatorAbsences = this.operator.absences || []
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    openOperatorAbsenceDialog(action, item) {
      if (!this.isAllowedAbsenceManagement) this.$router.push('/notPurchased')

      // Solo crear o borrar. No se puede modificar una ausencia ya creada
      this.showDialog = true
      this.actionDialog = action
      this.absenceItem = item
    },
    closeOperatorAbsenceDialog(reload) {
      this.showDialog = false
      this.actionDialog = ''
      if (reload) {
        var operatorElement = this.operators.find(
          (op) => op.id === this.operator.id
        )
        if (operatorElement) {
          this.operatorAbsences = operatorElement.absences || []
        }
      }
    },
    openOperatorAbsenceHistoryDialog() {
      if (!this.isAllowedAbsenceManagement) this.$router.push('/notPurchased')

      this.showAbsenceHistoryDialog = true
    },
    closeOperatorAbsenceHistoryDialog() {
      this.showAbsenceHistoryDialog = false
    },
    async getSubstitute(ref) {
      if (!this.substitutes[ref]) {
        const substitute = await getOperator(ref.id)
        this.$set(this.substitutes, ref, substitute)
      }
      return this.substitutes[ref]
    },
    formatDate(dateString) {
      if (!dateString) return ''
      const [year, month, day] = dateString.split('-')
      return `${day}-${month}-${year}`
    },
  },
  computed: {
    ...mapGetters([
      'isMobile',
      'isAdminUser',
      'isAdminAndCanManageUsers',
      'isAllowedAbsenceManagement',
    ]),
    computedHeaders() {
      const headers = [
        {
          text: this.$t('operatorAbsenceHistory.initialDate'),
          value: 'initialDate',
        },
        {
          text: this.$t('operatorAbsenceHistory.finalDate'),
          value: 'finalDate',
        },
        {
          text: this.$t('substituteOperator'),
          value: 'substituteName',
        },
        {
          text: this.$t('email'),
          value: 'substituteMail',
        },
        {
          //text: this.$t('operatorActions.title.cancel-absence'),
          text: '',
          value: 'deleteItem',
        },
      ]
      return headers
    },
    computedSubstitutes() {
      this.operatorAbsences.forEach((absence) => {
        if (absence.substituteRef && !this.substitutes[absence.substituteRef]) {
          this.getSubstitute(absence.substituteRef)
        }
      })
      return this.substitutes
    },
    phase1Condition() {
      // en la fase 1 solo permitimos añadir 1 ausencia
      return this.operatorAbsences.length > 0
    },
  },
}
</script>
<style scoped></style>
