var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"9","md":"7","lg":"5","xl":"4","align":"center","justify":"center"}},[_c('v-card',{attrs:{"outlined":"","color":"transparent"}},[_c('v-form',{ref:"loginForm"},[_c('v-container',{attrs:{"fill-height":"","fluid":""}},[(!_vm.is2FA)?_c('v-row',{attrs:{"align":"center","justify":"center"}},[(!_vm.isMobile)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-img',{staticClass:"mx-auto",attrs:{"contain":"","src":_vm.getImageSource,"max-width":"300"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"disabled":_vm.loading,"rules":_vm.userRules,"label":_vm.$t('username'),"dark":_vm.isMobile,"id":"input-username"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1),(!_vm.isResetPassword)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"disabled":_vm.loading,"rules":_vm.passwordRules,"label":_vm.$t('password'),"type":_vm.showPassword ? 'text' : 'password',"autocomplete":"on","append-icon":_vm.passwordIcons[_vm.showPassword],"dark":_vm.isMobile,"id":"input-password"},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-checkbox',{attrs:{"disabled":_vm.loading,"label":_vm.$t('keepSession'),"dark":_vm.isMobile,"id":"input-session"},model:{value:(_vm.keepSession),callback:function ($$v) {_vm.keepSession=$$v},expression:"keepSession"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{class:_vm.isMobile
                      ? 'secondary primary--text'
                      : 'primary secondary--text',attrs:{"loading":_vm.loading,"disabled":_vm.loading,"block":"","dark":_vm.isMobile,"id":"login"},on:{"click":function($event){_vm.isResetPassword ? _vm.userResetPassword() : _vm.userLogin()}},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('v-progress-circular',{attrs:{"indeterminate":"","size":"20","width":"2"}}),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.$t( _vm.isResetPassword ? 'resettingPassword' : 'loggingIn' ))+" ")])]},proxy:true}],null,false,1401428023)},[_vm._v(" "+_vm._s(_vm.$t(_vm.isResetPassword ? 'resetPassword' : 'login'))+" ")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('span',{class:_vm.loading
                      ? 'grey--text'
                      : _vm.isMobile
                      ? 'secondary--text'
                      : 'primary--text',style:({
                    cursor: _vm.loading ? 'default' : 'pointer',
                  }),on:{"click":_vm.changeIsResetPassword}},[_vm._v(" "+_vm._s(_vm.$t(_vm.isResetPassword ? 'login' : 'forgotPassword'))+" ")])])],1):_c('v-row',{attrs:{"align":"center","justify":"center"}},[(!_vm.isMobile)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-img',{staticClass:"mx-auto",attrs:{"contain":"","src":_vm.getImageSource,"max-width":"300"}})],1):_vm._e(),(!_vm.sent2FA)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.resolver2FA?.hints,"item-text":"phoneNumber","rules":_vm.codeRules,"label":_vm.$t('select2FA'),"id":"input-select2FA","return-object":""},model:{value:(_vm.selected2FA),callback:function ($$v) {_vm.selected2FA=$$v},expression:"selected2FA"}})],1):_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"disabled":_vm.loading,"rules":_vm.codeRules,"label":_vm.$t('code2FA'),"dark":_vm.isMobile,"id":"input-code"},model:{value:(_vm.code2FA),callback:function ($$v) {_vm.code2FA=$$v},expression:"code2FA"}})],1),_c('div',{attrs:{"id":"recaptcha-containter"}}),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{class:_vm.isMobile
                      ? 'secondary primary--text'
                      : 'primary secondary--text',attrs:{"loading":_vm.loading,"disabled":_vm.loading,"block":"","dark":_vm.isMobile,"id":"2fa"},on:{"click":function($event){_vm.sent2FA ? _vm.verify2FA() : _vm.send2FA()}},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('v-progress-circular',{attrs:{"indeterminate":"","size":"20","width":"2"}}),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.$t(_vm.sent2FA ? 'verifying2FA' : 'sending2FA'))+" ")])]},proxy:true}])},[_vm._v(" "+_vm._s(_vm.$t(_vm.sent2FA ? 'verify2FA' : 'send2FA'))+" ")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('span',{class:_vm.loading
                      ? 'grey--text'
                      : _vm.isMobile
                      ? 'secondary--text'
                      : 'primary--text',style:({
                    cursor: _vm.loading ? 'default' : 'pointer',
                  }),on:{"click":_vm.exit2FA}},[_vm._v(" "+_vm._s(_vm.$t('exit2FA'))+" ")])])],1),(!_vm.isResetPassword)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{class:_vm.isMobile
                      ? 'secondary primary--text'
                      : 'primary secondary--text',attrs:{"disabled":_vm.loading,"dark":_vm.isMobile},on:{"click":function($event){return _vm.goMicrosoftAuth()}}},[_c('v-img',{attrs:{"src":require("@/assets/microsoft-logo.png"),"max-width":"25"}}),_c('span',[_vm._v("  "+_vm._s(_vm.$t('microsoftAuth.company')))])],1)],1)],1):_vm._e()],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }