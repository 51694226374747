<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col
        cols="12"
        sm="9"
        md="7"
        lg="5"
        xl="4"
        align="center"
        justify="center"
      >
        <v-card outlined color="transparent">
          <v-form ref="loginForm">
            <v-container fill-height fluid>
              <v-row align="center" justify="center">
                <v-col cols="12" v-if="!isMobile">
                  <v-img
                    contain
                    class="mx-auto"
                    :src="getImageSource"
                    max-width="300"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="username"
                    :disabled="loading"
                    :rules="userRules"
                    :label="$t('microsoftAuth.username')"
                    :dark="isMobile"
                    id="input-username"
                    @keydown.enter="handleEnter"
                  />
                </v-col>
                <v-col cols="12">
                  <v-btn
                    :loading="loading"
                    :disabled="loading"
                    :class="
                      isMobile
                        ? 'secondary primary--text'
                        : 'primary secondary--text'
                    "
                    :dark="isMobile"
                    block
                    @click="authAzure()"
                    id="login"
                  >
                    <v-img src="@/assets/microsoft-logo.png" max-width="25" />
                    <span>
                      &nbsp;&nbsp;&nbsp; {{ $t('microsoftAuth.login') }}</span
                    >
                    <template v-slot:loader>
                      <v-progress-circular indeterminate size="20" width="2" />
                      <span class="ml-2">
                        {{ $t('microsoftAuth.loggingIn') }}
                      </span>
                    </template>
                  </v-btn>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <span
                    @click="goBack()"
                    :class="
                      loading
                        ? 'grey--text'
                        : isMobile
                        ? 'secondary--text'
                        : 'primary--text'
                    "
                    :style="{
                      cursor: loading ? 'default' : 'pointer',
                    }"
                  >
                    {{ $t('microsoftAuth.exit') }}
                  </span>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Auth } from '@/firebase-exports'
import { createTrace } from '@/services/trace-service'
import { getIp } from '@/utils/ip-utils.js'
import rules from '@/utils/rules'
import getErrorText from '@/utils/get-error-text'
import { mapGetters, mapMutations } from 'vuex'
import { PublicClientApplication } from '@azure/msal-browser'
import AzureService from '@/services/azure-service'
import { validateExternalAuth } from '@/services/user-service'

export default {
  props: {
    usernameProp: String,
  },
  data() {
    return {
      loading: false,
      username: '',
      userRules: [rules.required, rules.email],
      token: '',
      msalInstance: {},
      account: {},
    }
  },
  methods: {
    ...mapMutations(['setSnackbar', 'setMsalInstance']),
    goBack() {
      this.$emit('login-change', '', this.username)
    },
    async authAzure() {
      if (!this.$refs.loginForm.validate()) return

      this.loading = true

      try {
        // Validar que el usuario existe en GoodOK y tiene activo la autenticación con Microsoft.
        // Una vez validado, recibe el clientId y authority para poder autenticarse con Microsoft,
        // y el token de usuario para autenticarse en GoodOK.
        const externalAuth = await validateExternalAuth(this.username)
        // console.log('validateExternalAuth() - externalAuth: ', externalAuth)
        if (
          externalAuth?.disabled ||
          !externalAuth?.enabled ||
          !externalAuth?.allowed ||
          externalAuth?.type != 'Microsoft'
        ) {
          this.loading = false
          return this.setSnackbar({
            position: 'top',
            type: 'error',
            message: this.$t('microsoftAuth.errorExternalAuth'),
          })
        }
        const clientId = externalAuth.clientId
        const authority = externalAuth.authority
        this.token = externalAuth.token

        const azureService = new AzureService(clientId, authority)
        this.msalInstance = new PublicClientApplication(
          azureService.getMsalConfig()
        )
        await this.msalInstance.initialize()
        // console.log(this.msalInstance)

        await this.msalInstance
          .loginPopup({ loginHint: this.username })
          .then(this.handleResponse)
          .catch((error) => {
            console.log(error)
            const message = getErrorText(error.message)
            this.setSnackbar({ position: 'top', type: 'error', message })
            this.loading = false
          })
      } catch (err) {
        const message = getErrorText(err.message)
        this.setSnackbar({ position: 'top', type: 'error', message })
        this.loading = false
      }
    },
    async userLogin(token) {
      try {
        const { user } = await Auth.signInWithEmailAndPassword(
          this.authData,
          token.username,
          token.password
        )

        getIp()
          .then(async (ip) => {
            await createTrace(user.uid, {
              actionType: 'logged',
              createdAt: new Date(),
              ip,
            })
          })
          .catch(async () => {
            await createTrace(user.uid, {
              actionType: 'logged',
              createdAt: new Date(),
            })
          })
      } catch (err) {
        const message = getErrorText(err.message)
        this.setSnackbar({ position: 'top', type: 'error', message })
        this.loading = false
      }
    },
    handleResponse(response) {
      this.account = response.account
      // Después de un inicio de sesión correcto, configurar la cuenta activa para que sea la del usuario que acaba de iniciar sesión.
      this.msalInstance.setActiveAccount(this.account)
      // Comprobar que los usuarios de GoodOK y Microsoft coinciden.
      if (
        this.account === null ||
        this.account.username.trim().toLowerCase() !=
          this.username.trim().toLowerCase()
      ) {
        this.loading = false
        return this.setSnackbar({
          position: 'top',
          type: 'error',
          message: this.$t('microsoftAuth.errorDifferentUsers'),
        })
      }
      this.setMsalInstance(this.msalInstance)
      // Autenticarse en GoodOK mediante el token del usuario.
      this.userLogin(
        JSON.parse(Buffer.from(this.token, 'base64').toString('utf8'))
      )
      this.loading = false
    },
    async handleEnter(event) {
      await this.authAzure()
      event.preventDefault()
    },
  },
  computed: {
    ...mapGetters(['isMobile']),
    getImageSource() {
      if (this.$store.state.brand?.loginLogo?.startsWith('http')) {
        return this.$store.state.brand.loginLogo
      } else {
        return require('@/assets/login-logo.png')
      }
    },
  },
  mounted() {
    this.username = this.usernameProp
    this.authData = Auth.getAuth()
  },
}
</script>
