<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-container>
          <v-row align="center" justify="center">
            <h1>{{ $t('settings') }}</h1>
          </v-row>
          <v-row align="center" justify="center">
            <h3 class="headline mb-1">{{ $t('manageProfileInfo') }}</h3>
          </v-row>
          <v-row align="center" justify="center">
            <v-col
              cols="12"
              :sm="canUploadCertificate ? '6' : '4'"
              :lg="canUploadCertificate ? '3' : '4'"
            >
              <SettingsAvatar ref="SettingsAvatar" />
            </v-col>
            <v-col
              cols="12"
              :sm="canUploadCertificate ? '6' : '4'"
              :lg="canUploadCertificate ? '3' : '4'"
            >
              <SettingsInformation ref="SettingsInformation" />
            </v-col>
            <!-- No mostrar cambio de contraseña en perfil si el usuario es de tipo singleSignOn -->
            <v-col
              v-if="!isSingleSignOnUser || hasExternalsAuths"
              cols="12"
              :sm="canUploadCertificate ? '6' : '4'"
              :lg="canUploadCertificate ? '3' : '4'"
            >
              <SettingsPassword
                ref="SettingsPassword"
                :externalsAuths="externalsAuths"
              />
            </v-col>
            <v-col v-if="canUploadCertificate" cols="12" sm="6" lg="3">
              <SettingsCertificateCard ref="SettingsCertificateCard" />
            </v-col>
          </v-row>
          <v-row v-if="isAdminUser" align="center" justify="center">
            <v-col cols="12">
              <LicensesResumeCard
                ref="LicensesResumeCard"
                :plan="$store.state.plan"
              />
            </v-col>
          </v-row>

          <v-row v-if="isSuperAdminUser" align="center" justify="center">
            <v-col cols="12">
              <TermsServiceCard ref="TermsServiceCard" />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row></v-row>
  </v-container>
</template>

<script>
import SettingsPassword from '@/components/settings/SettingsPassword'
import SettingsInformation from '@/components/settings/SettingsInformation'
import SettingsAvatar from '@/components/settings/SettingsAvatar'
import LicensesResumeCard from '@/components/settings/LicensesResumeCard'
import SettingsCertificateCard from '@/components/settings/SettingsCertificateCard'
import TermsServiceCard from '@/components/settings/TermsServiceCard'
import { mapGetters } from 'vuex'
import { getExternalsAuthsSubscription } from '@/services/externals-auths-service'

export default {
  components: {
    SettingsPassword,
    SettingsInformation,
    SettingsAvatar,
    LicensesResumeCard,
    SettingsCertificateCard,
    TermsServiceCard,
  },
  data() {
    return {
      externalsAuthsUnsubcribe: () => {},
      externalsAuths: [],
    }
  },
  async created() {
    this.externalsAuthsUnsubcribe = await getExternalsAuthsSubscription(
      (externalsAuths) => {
        this.externalsAuths = externalsAuths
      }
    )
  },
  async destroyed() {
    if (this.externalsAuthsUnsubcribe) this.externalsAuthsUnsubcribe
  },
  computed: {
    ...mapGetters([
      'isAdminUser',
      'isSuperAdminUser',
      'isSuperOperatorUser',
      'isAdminAndCanUploadCertificates',
      'isNotificacionesActivasEmpresa',
      'isSingleSignOnUser',
      'isTermsAccepted',
      'hasExternalsAuths',
    ]),
    canUploadCertificate() {
      return (
        this.isNotificacionesActivasEmpresa &&
        this.isTermsAccepted && // no permitir subir un certificado hasta no haber aceptado los terminos.
        (this.isAdminAndCanUploadCertificates ||
          this.isSuperAdminUser ||
          this.isSuperOperatorUser)
      )
    },
  },
  watch: {
    '$i18n.locale': function () {
      this.$refs.SettingsPassword.$refs.changePasswordForm.resetValidation()
      this.$refs.SettingsInformation.$refs.informationForm.resetValidation()
      this.$refs.SettingsAvatar.$refs.avatarForm.resetValidation()
    },
  },
}
</script>
