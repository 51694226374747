var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"9","md":"7","lg":"5","xl":"4","align":"center","justify":"center"}},[_c('v-card',{attrs:{"outlined":"","color":"transparent"}},[_c('v-form',{ref:"loginForm"},[_c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[(!_vm.isMobile)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-img',{staticClass:"mx-auto",attrs:{"contain":"","src":_vm.getImageSource,"max-width":"300"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"disabled":_vm.loading,"rules":_vm.userRules,"label":_vm.$t('microsoftAuth.username'),"dark":_vm.isMobile,"id":"input-username"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.handleEnter.apply(null, arguments)}},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{class:_vm.isMobile
                      ? 'secondary primary--text'
                      : 'primary secondary--text',attrs:{"loading":_vm.loading,"disabled":_vm.loading,"dark":_vm.isMobile,"block":"","id":"login"},on:{"click":function($event){return _vm.authAzure()}},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('v-progress-circular',{attrs:{"indeterminate":"","size":"20","width":"2"}}),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.$t('microsoftAuth.loggingIn'))+" ")])]},proxy:true}])},[_c('v-img',{attrs:{"src":require("@/assets/microsoft-logo.png"),"max-width":"25"}}),_c('span',[_vm._v("     "+_vm._s(_vm.$t('microsoftAuth.login')))])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{class:_vm.loading
                      ? 'grey--text'
                      : _vm.isMobile
                      ? 'secondary--text'
                      : 'primary--text',style:({
                    cursor: _vm.loading ? 'default' : 'pointer',
                  }),on:{"click":function($event){return _vm.goBack()}}},[_vm._v(" "+_vm._s(_vm.$t('microsoftAuth.exit'))+" ")])])],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }