<template>
  <v-dialog :value="true" persistent :max-width="1180">
    <v-card class="pa-0">
      <v-card-title
        :style="{
          backgroundColor: $vuetify.theme.themes.light.primary,
          color: $vuetify.theme.themes.light.secondary,
        }"
      >
        <v-row align="center">
          <v-col cols="1"> </v-col>
          <v-col cols="10" class="d-flex justify-center">
            <v-row class="d-flex justify-center" align="center">
              {{ $t('operatorActions.title.history-absences') }}
              <span class="body-2 ml-2 mt-1">({{ history.length }})</span>
              <DialogButton
                section="absences"
                subsection="history"
                hideSubtitle
                classStr="ml-4"
              />
            </v-row>
          </v-col>

          <v-col cols="1" class="d-flex justify-end pa-0">
            <v-btn
              icon
              x-large
              @click="closeDialog"
              id="close-absence-history-dialog"
            >
              <v-icon size="32"> mdi-close </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text class="body-1">
        <v-col align="center">
          <h2 class="mt-1">
            {{ `${this.operator.name} ${this.operator.surname}` }}
          </h2>
          <div class="body-2">
            {{ this.operator.email }}
          </div>
        </v-col>
      </v-card-text>

      <v-card-text max-height="200">
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="history"
          :items-per-page="5"
          :footer-props="{ 'items-per-page-options': [5, 10, 15 /*-1*/] }"
          :sort-by="['createdAt']"
          :sort-desc="[true]"
          class="elevation-1"
          :no-data-text="$t('emptyOperatorAbsencesHistory')"
        >
          <template v-slot:[`item.createdAt`]="{ item }">
            <v-container>
              <v-row align="center">
                {{ $d(item.createdAt, 'i18nDateAndHour') }}
              </v-row>
            </v-container>
          </template>

          <template v-slot:[`item.actionType`]="{ item }">
            <v-container>
              <v-row align="center">
                {{ $t('absenceActions.' + item.actionType) }}
              </v-row>
            </v-container>
          </template>

          <template v-slot:[`item.createdBy`]="{ item }">
            <v-container>
              <v-row align="center">
                {{
                  getShortText(getOperatorNameSurnameFromId(item.createdBy), 40)
                }}
              </v-row>
            </v-container>
          </template>

          <template v-slot:[`item.initialDate`]="{ item }">
            {{ formatDate(item.initialDate) }}
          </template>

          <template v-slot:[`item.finalDate`]="{ item }">
            {{ formatDate(item.finalDate) }}
          </template>

          <template v-slot:[`item.substituteId`]="{ item }">
            <v-container>
              <v-row align="center">
                {{
                  getShortText(
                    getOperatorNameSurnameFromId(item.substituteId),
                    40
                  )
                }}
              </v-row>
            </v-container>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import DialogButton from '@/components/documentation/buttons/DialogButton'
import { getOperator } from '@/services/operator-service'

export default {
  props: {
    operator: Object,
    operators: {
      type: Array,
      required: true,
    },
    history: {
      type: Array,
      required: true,
    },
  },
  components: {
    DialogButton,
  },
  data() {
    return {
      headers: [
        {
          text: this.$t('operatorAbsenceHistory.createdAt'),
          align: 'start',
          sortable: true,
          value: 'createdAt',
        },
        {
          text: this.$t('operatorAbsenceHistory.actionType'),
          align: 'start',
          sortable: true,
          value: 'actionType',
        },
        {
          text: this.$t('operatorAbsenceHistory.createdBy'),
          align: 'start',
          sortable: true,
          value: 'createdBy',
        },
        {
          text: this.$t('operatorAbsenceHistory.initialDate'),
          align: 'start',
          sortable: true,
          value: 'initialDate',
        },
        {
          text: this.$t('operatorAbsenceHistory.finalDate'),
          align: 'start',
          sortable: true,
          value: 'finalDate',
        },
        {
          text: this.$t('operatorAbsenceHistory.substitute'),
          align: 'start',
          sortable: true,
          value: 'substituteId',
        },
      ],
      loadedOperatorsCache: [], // Caché para operadores cargados dinámicamente
      loading: true,
    }
  },
  async created() {
    this.loading = false
  },
  computed: {
    ...mapGetters(['isMobile', 'isAdminUser', 'isAdminAndCanManageUsers']),
  },
  methods: {
    formatDate(dateString) {
      if (!dateString) return ''
      const [year, month, day] = dateString.split('-')
      return `${day}-${month}-${year}`
    },
    closeDialog() {
      this.$emit('closeOperatorAbsenceHistoryDialog')
    },
    getOperatorNameSurnameFromId(operatorId) {
      // Caso Autor: scheduler
      if (operatorId == 'TA scheduler') operatorId = 'Acción Programada'
      if (
        operatorId == 'TA' ||
        operatorId == 'Acción Programada' ||
        operatorId == 'Automático'
      )
        return operatorId

      // Si ya está en la lista inicial recibida en la propiedad o ya está en la caché, devolver directamente
      const operator =
        this.operators.find((o) => o.id === operatorId) ||
        this.loadedOperatorsCache[operatorId]

      if (operator) {
        return `${operator.name} ${operator.surname}`
      }
      // Si no se encuentra, devolver un valor por defecto
      return this.$t('operatorNotFound')
    },
    getShortText(text, maxLength = 25) {
      return text?.length > maxLength
        ? text.substring(0, maxLength) + '...'
        : text
    },
    async loadAllOperatorNames() {
      // Cargar todos los operadores que no estén ya en el array o en caché
      const idsToResolve = this.history
        .map((trace) => trace.createdBy)
        .filter(
          (id) =>
            !this.operators.find((o) => o.id === id) &&
            !this.loadedOperatorsCache[id]
        )

      await Promise.all(
        idsToResolve.map(async (id) => {
          try {
            // Si no está cargado en el array inicial ni en cache, leerlo a través del servicio
            const operator = await getOperator(id)

            // Si se encuentra el operador, guardar en la caché
            if (operator) {
              // Guardar en la caché y devolver
              this.$set(this.loadedOperatorsCache, id, operator)
            }
          } catch (error) {
            console.error(`Error reading operator with id ${id}:`, error)
            return `Error reading operator`
          }
        })
      )
    },
  },
  watch: {
    // Cargar datos dinámicamente cuando cambien los items de la tabla
    history: {
      immediate: true, // Se ejecuta de inmediato al cargar el componente
      handler(newTraces) {
        if (newTraces && newTraces.length > 0) {
          this.loadAllOperatorNames()
        }
      },
    },
  },
}
</script>
<style scoped></style>
