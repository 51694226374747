<template>
  <v-dialog :value="true" persistent :max-width="isDelete ? 570 : 1000">
    <v-card class="pa-0">
      <v-card-title>
        <v-row align="center">
          <v-col cols="1"> </v-col>
          <v-col cols="10" class="d-flex justify-center">
            <span> {{ $t(`operatorActions.title.${action}`) }} </span>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text class="body-1">
        <v-col align="center">
          <h2 class="mt-1">
            {{ `${this.operator.name} ${this.operator.surname}` }}
          </h2>
          <div class="body-2">
            {{ this.operator.email }}
          </div>

          <div v-if="isDelete" class="mt-3">
            <v-row justify="center" align="center" class="text-center">
              <v-col cols="auto">
                {{
                  `${$t('initialDate')}: ${formatDate(
                    this.absenceItem.initialDate
                  )}`
                }}
              </v-col>
              <v-col cols="auto">
                {{
                  `${$t('finalDate')}: ${formatDate(
                    this.absenceItem.finalDate
                  )}`
                }}
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-card-text>

      <v-card-text v-if="isCreate">
        <v-form
          ref="operatorAbsenceForm"
          v-model="validOperatorAbsenceForm"
          lazy-validation
          @submit.prevent="onSubmit"
        >
          <v-row class="mt-4" align="baseline">
            <template>
              <v-col cols="6" class="mt-0 mb-2">
                <v-menu
                  dense
                  class="ma-0 pa-0"
                  ref="initialDateMenu"
                  v-model="initialDateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="initialDate"
                      :label="`${$t('initialDate')}`"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      id="operator-absence-initial-date"
                      :disabled="loading"
                      :rules="noRestrictionDateSelected ? [notEmpty] : []"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    @click:date="
                      () => {
                        $refs.initialDateMenu.save(initialDate)
                        overlapFilterOperators()
                      }
                    "
                    v-model="initialDate"
                    :locale="$t('javascriptLocale')"
                    :min="new Date().toISOString().substring(0, 10)"
                    :max="maximumDate"
                    no-title
                    scrollable
                    :rules="[notEmpty]"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="6" class="mt-0 mb-2">
                <v-menu
                  dense
                  class="ma-0 pa-0"
                  ref="finalDateMenu"
                  v-model="finalDateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="finalDate"
                      :label="`${$t('finalDate')}`"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      id="operator-absence-final-date"
                      :disabled="loading"
                      :rules="noRestrictionDateSelected ? [notEmpty] : []"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    @click:date="
                      () => {
                        $refs.finalDateMenu.save(finalDate)
                        overlapFilterOperators()
                      }
                    "
                    v-model="finalDate"
                    :locale="$t('javascriptLocale')"
                    :min="minimumDate"
                    :max="maximumDate"
                    no-title
                    scrollable
                    :rules="[notEmpty]"
                    :disabled="!initialDate"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" class="mt-0 mb-2">
                <v-autocomplete
                  @input="setSubstitute"
                  dense
                  v-model="selectedOperator"
                  :items="operators"
                  color="primary"
                  :filter="searchFilterOperators"
                  :no-data-text="$t('emptyOperators')"
                  :label="$t('selectOperator')"
                  item-value="id"
                  item-text="getOperatorDisplay"
                  :disabled="
                    loading ||
                    !initialDate ||
                    !finalDate ||
                    !canOperatorBeAbsent(operator, initialDate, finalDate)
                  "
                  prepend-icon="mdi-account"
                  :menu-props="{ bottom: true, offsetY: true }"
                  id="operator-absence-substitute"
                  :rules="[notEmpty]"
                >
                  <template v-slot:item="{ item }">
                    <span id="operator-absence-substitute-option">
                      {{ getOperatorDisplay(item) }}
                    </span>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span>
                      {{ getOperatorDisplay(item) }}
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>

              <!-- alerta control de operador no permite sustitucion en ese periodo -->
              <div
                style="display: flex; justify-content: center; width: 100%"
                v-if="operatorBlocked"
              >
                <v-alert type="error" color="red" style="max-width: 80%">
                  <span>{{ $t('operatorAbsenceBlocked') }}</span>
                  <div v-if="blockingAbsence">
                    {{
                      $t('blockedByAbsence', {
                        fullname: getOperatorFullname(
                          blockingAbsence.substituteForId
                        ),
                        startDate: formatDate(blockingAbsence.initialDate),
                        endDate: formatDate(blockingAbsence.finalDate),
                      })
                    }}
                  </div>
                </v-alert>
              </div>
            </template>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="headline justify-center">
        <v-btn
          :disabled="loading"
          color="error"
          rounded
          class="white--text"
          width="150px"
          @click="closeDialog"
          id="cancel"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          rounded
          color="accept"
          class="white--text"
          width="150px"
          :loading="loading"
          :disabled="loading || (isCreate && !this.substituteId)"
          @click="performAction"
          id="confirm"
        >
          {{ $t('confirm') }}
          <template v-slot:loader>
            <v-progress-circular indeterminate size="20" width="2" />
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from '@/utils/rules'
import { mapGetters, mapMutations } from 'vuex'
import {
  getUserOperators,
  addOperatorAbsence,
  deleteOperatorAbsence,
  sendMailOperatorAbsence,
} from '@/services/operator-service'
import { createTrace } from '@/services/trace-service'
import getErrorText from '@/utils/get-error-text'
import { getUserInfo } from '@/services/user-service'
const lodash = require('lodash')

export default {
  props: {
    action: String,
    operator: Object,
    absenceItem: Object,
    substituteCache: Array,
  },
  data() {
    return {
      validOperatorAbsenceForm: false,
      initialDate: '',
      initialDateMenu: false,
      finalDate: '',
      finalDateMenu: false,
      selectedOperator: undefined,
      substituteId: '',
      showDialog: false,
      loading: false,
      reload: false,
      operators: [], // los posibles sustitutos
      initialDisabledOperators: [],
      operatorBlocked: false,
    }
  },
  async created() {
    if (this.isCreate) {
      this.loading = true
      await Promise.all([
        (async () => (this.operators = await getUserOperators()))(),
      ])

      // Incluir al usuario superadmin en la lista de operadores elegibles
      const userId = this.$store.state.user.parentRef
        ? this.$store.state.user.parentRef.id
        : this.$store.state.user.id
      this.operators.push(await getUserInfo(userId))
      // y ordena de nuevo con el nuevo usuario incluido, para que superadmin no aparezca el ultimo
      // mejora: si los nombres son iguales, compara por surname
      this.operators.sort((a, b) => {
        const nameComparison = a.name.localeCompare(b.name)
        return nameComparison !== 0
          ? nameComparison
          : a.surname.localeCompare(b.surname)
      })

      this.loading = false

      // si en el array de operadores está (que debería estar) el operador al que se quiere crear una ausencia, podemos
      // opcion a) deshabilitarlo para que se vea dicho operador en la lista pero no se pueda seleccionar como sustituto
      this.updateOperatorsArray(this.operators, this.operator.id, 'disable')

      // o la opcion b) eliminarlo del array, lo dejo configurable por si se decide cambiarlo mas adelante ...
      //this.updateOperatorsArray(this.operators, this.operator.id, 'remove')

      // segun se indica en el documento de requisitos:
      // 1. Hay que excluir del listado operadores que estén deshabilitados :
      //  -> ahora mismo los muestro deshabilitados y no se pueden seleccionar, sirve así o los elimino del selector ?
      //
      // 2. y operadores que estén en modo sustitución activo, es decir, que tienen el modo sustitucón activado y
      // están dentro de las fechas de inicio y fin.
      //
      // Operadores que están inicialmente deshabilitados ( o eliminados si activamos la opcion de 'remove', a elegir).
      this.initialDisabledOperators = lodash.cloneDeep(this.operators)

      // Despues de este filtrado, cuando se introduzca una fecha inicial y una fecha final de una nueva ausencia,
      // se volverá a aplicar otro filtro en funcion de dichas fechas inicial y final para evitar el solapamiento.
      // Guardamos los operadores inicialmente deshabilitados para poder restaurarlos antes de cada cambio de filtro.
    }
  },
  computed: {
    ...mapGetters(['isMobile', 'isAllowedAbsenceManagement']),
    isCreate() {
      return this.action === 'create-absence'
    },
    isDelete() {
      return this.action === 'cancel-absence'
    },
    minimumDate() {
      if (!this.initialDate) return new Date().toISOString().substring(0, 10)
      else return new Date(this.initialDate).toISOString().substring(0, 10)
    },
    maximumDate() {
      // Fecha final sin limitaciones
      //if (!this.finalDate) return null
      //else return new Date(this.finalDate).toISOString().substring(0, 10)

      // Fecha final limitada segun la fecha inicial
      if (!this.initialDate) {
        return null
      } else {
        const initialDateObj = new Date(this.initialDate)
        // Calcular la fecha máxima como 60 días después de initialDate
        //initialDateObj.setDate(initialDateObj.getDate() + 60)

        // Calcular la fecha máxima como 6 meses después de initialDate
        initialDateObj.setMonth(initialDateObj.getMonth() + 6)
        return initialDateObj.toISOString().substring(0, 10)
      }
    },
    noRestrictionDateSelected() {
      return this.initialDate === '' && this.finalDate === ''
    },
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    ...rules,
    async performAction() {
      if (!this.isAllowedAbsenceManagement)
        // NO debería poder llegar hasta aqui, es redundante, pero por si acaso.
        this.$router.push('/notPurchased')

      // Comprobamos los campos del formulario
      if (!this.isDelete && !this.$refs.operatorAbsenceForm?.validate()) return

      try {
        this.loading = true

        if (this.isCreate) {
          const actionType = 'createAbsence'
          const operatorAbsence = {
            initialDate: this.initialDate,
            finalDate: this.finalDate,
            substituteId: this.substituteId,
            createdById: this.$store.state.user.id,
          }

          // actualiza los datos del operador sustituido y del operador sustituto
          await addOperatorAbsence(this.operator.id, operatorAbsence)

          // En la colección trace, guardar una entrada de sustitución programada con:
          // un identificador de esta acción, la fecha en la que se programó, el operador/gestor que la programó y los datos (fechas y sustituto).
          await createTrace(this.operator.id, {
            actionType: actionType,
            createdAt: new Date(),
            createdBy: this.$store.state.user.id,
            initialDate: this.initialDate,
            finalDate: this.finalDate,
            substituteId: this.substituteId,
          })

          // Envío de correos, requisitos:
          // - Enviar correo al operador a sustituir de que han configurado su sustitución en las fechas y por parte del operador sustituto.
          // - Enviar correo al operador sustituto de que le han configurado como sustituto del operador a sustituir y las fechas para ello.
          const absenceData = {
            actionType: actionType,
            initialDate: this.initialDate,
            finalDate: this.finalDate,
            substituteId: this.substituteId,
          }
          await sendMailOperatorAbsence(this.operator.id, absenceData)

          this.reload = true
        } else if (this.isDelete) {
          const actionType = 'cancelAbsence'

          // actualiza los datos del operador sustituido y del operador sustituto
          await deleteOperatorAbsence(this.operator.id, this.absenceItem)

          // En la colección trace, guardar una entrada de cancelación de sustitución programada con:
          // un identificador de esta acción, la fecha en la que se canceló, el operador que la canceló y los datos (fechas y sustituto).
          await createTrace(this.operator.id, {
            actionType: actionType,
            createdAt: new Date(),
            createdBy: this.$store.state.user.id,
            initialDate: this.absenceItem.initialDate,
            finalDate: this.absenceItem.finalDate,
            substituteId: this.absenceItem.substituteRef.id,
          })

          // Enviar correo a los dos operadores (a sustituir y sustituto) de que se ha cancelado la sustitución.
          const absenceData = {
            actionType: actionType,
            initialDate: this.absenceItem.initialDate,
            finalDate: this.absenceItem.finalDate,
            substituteId: this.absenceItem.substituteRef.id,
          }
          await sendMailOperatorAbsence(this.operator.id, absenceData)

          this.reload = true
        } else {
          // no hay actualizaciones de ausencias, hay que borrarla y crear otra nueva
          return
        }

        // Mostramos snackbar con el mensaje de exito
        const message = this.$t('operatorActions.success.' + this.action)
        this.setSnackbar({ position: 'top', type: 'success', message })
        this.closeDialog()
      } catch (error) {
        // Mostramos snackbar con el mensaje de error
        const message = getErrorText(error.message)
        this.setSnackbar({ position: 'top', type: 'error', message })
      } finally {
        this.loading = false
        this.reload = true
      }
    },
    closeDialog() {
      this.$emit('closeOperatorAbsenceDialog', this.reload)
    },
    searchFilterOperators(item, queryText) {
      const name = item.name.toLowerCase() + ' ' + item.surname.toLowerCase()
      const searchText = queryText.toLowerCase()
      return name.toLowerCase().includes(searchText)
    },
    overlapFilterOperators() {
      if (this.selectedOperator) {
        this.selectedOperator = null
        this.substituteId = null
      }
      this.updatePossibleOperators(this.initialDate, this.finalDate)
    },
    updatePossibleOperators(newStartDate, newEndDate) {
      // se necesitan ambas fechas para comprobar el solapamiento
      if (!newStartDate || !newEndDate) return

      const newStart = this.formatDateToYYYYMMDD(newStartDate)
      const newEnd = this.formatDateToYYYYMMDD(newEndDate)

      this.operators.forEach((operator) => {
        const initiallyDisabled = this.initialDisabledOperators.find(
          (initialOperator) => initialOperator.id === operator.id
        )?.disabled

        if (initiallyDisabled) {
          operator.disabled = true
        } else if (operator.absences?.length) {
          // Buscamos si alguna ausencia existente se solapa con el nuevo periodo introducido
          const hasOverlap = operator.absences.some((absence) => {
            const absenceStart = this.formatDateToYYYYMMDD(absence.initialDate)
            const absenceEnd = this.formatDateToYYYYMMDD(absence.finalDate)
            return (
              (newStart <= absenceEnd && newStart >= absenceStart) || // Inicio dentro del rango
              (newEnd <= absenceEnd && newEnd >= absenceStart) || // Fin dentro del rango
              (newStart <= absenceStart && newEnd >= absenceEnd) // Nuevo rango cubre toda la ausencia
            )
          })
          // Deshabilitamos al operador si hay solapamiento de fechas
          if (hasOverlap) {
            operator.disabled = true
            operator.disabledReason = 'overlap'
          } else {
            if (!hasOverlap && operator.disabledReason == 'overlap') {
              operator.disabled = false
              operator.disabledReason = null
            }
            if (operator.disabled !== true) {
              operator.disabled = false
              operator.disabledReason = null
            }
          }
        } else {
          // No tiene ausencias
          operator.disabled = false
        }
      })
    },
    setSubstitute(userId) {
      this.substituteId = userId
    },
    getOperatorDisplay(operator) {
      if (operator.disabled) {
        if (operator.disabledReason == 'overlap') {
          return `${operator.name} ${operator.surname} - ${this.$t(
            'operatorDisabledOverlap'
          )}`
        } else if (operator.disabledReason == 'himself') {
          return `${operator.name} ${operator.surname} - ${this.$t(
            'operatorDisabledHimself'
          )}`
        } else {
          return `${operator.name} ${operator.surname} - ${this.$t(
            'operatorDisabled'
          )}`
        }
      }
      return `${operator.name} ${operator.surname}`
    },
    updateOperatorsArray(array, id, action) {
      const index = array.findIndex((item) => item.id === id)

      if (index !== -1) {
        if (action === 'disable') {
          array[index].disabled = true
          array[index].disabledReason = 'himself'
        } else if (action === 'remove') {
          array.splice(index, 1)
        }
      }
    },
    formatDate(dateString) {
      if (!dateString) return ''
      const [year, month, day] = dateString.split('-')
      return `${day}-${month}-${year}`
    },
    formatDateToYYYYMMDD(date) {
      const d = new Date(date)
      const year = d.getFullYear()
      const month = String(d.getMonth() + 1).padStart(2, '0')
      const day = String(d.getDate()).padStart(2, '0')
      return `${year}${month}${day}`
    },
    canOperatorBeAbsent(operator, newStartDate, newEndDate) {
      // Esta funcion sirve para cumplir con el siguiente punto de los requisitos:
      //
      //    No vamos a soportar sustituciones “en cascada”. No se puede poner en modo sustitución
      //    a un operador que está sustituyendo a otros. Primero hay que quitarle de sustituto del
      //    operador u operadores a los que sustituye y cuando “está limpio” se le puede marcar como
      //    sustituido y configurarle las fechas y su sustituto.

      if (!newStartDate || !newEndDate) return true // Si no hay fechas, no hay restricciones

      const newStart = this.formatDateToYYYYMMDD(newStartDate)
      const newEnd = this.formatDateToYYYYMMDD(newEndDate)
      let blockingAbsence = null

      // Revisamos si este operador está como sustituto en alguna ausencia de otro operador
      this.operators.some((op) => {
        return op.absences?.some((absence) => {
          const absenceStart = this.formatDateToYYYYMMDD(absence.initialDate)
          const absenceEnd = this.formatDateToYYYYMMDD(absence.finalDate)

          if (
            absence.substituteRef.id === operator.id && // El operador está sustituyendo a alguien
            ((newStart <= absenceEnd && newStart >= absenceStart) || // Inicio dentro del rango de sustitución
              (newEnd <= absenceEnd && newEnd >= absenceStart) || // Fin dentro del rango de sustitución
              (newStart <= absenceStart && newEnd >= absenceEnd)) // El nuevo rango cubre toda la sustitución
          ) {
            blockingAbsence = absence
            blockingAbsence.substituteForId = op.id // guardamos el operador al que sustituye

            return true // Detenemos la búsqueda al encontrar un solapamiento
          }
        })
      })

      this.blockingAbsence = blockingAbsence
      this.operatorBlocked = !!blockingAbsence
      return !blockingAbsence // Devuelve false si hay solapamiento, me sirve para deshabilitar el selector
    },
    getOperatorFullname(id) {
      let fullname
      // Lo mas probable es que el operador sustituto se encuentre en la lista de operadores,
      const oper = this.operators.find((o) => o.id === id)
      if (oper) {
        fullname = `${oper.name || ''} ${oper.surname || ''}`
      } else {
        // Si no está en la lista de operadores entonces comprueba si ya está cargado en caché
        if (this.substituteCache[id]) {
          fullname = this.substituteCache[id]
        } else {
          fullname = 'No se encontró el sustituto'
        }
      }
      return fullname
    },
  },
  watch: {
    initialDate(newDate) {
      // vamos a evitar a los listos que juegan con las fechas ...
      if (this.finalDate < newDate) {
        this.finalDate = null
      }
    },
  },
}
</script>
<style scoped></style>
