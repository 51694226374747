import vuetify from '@/plugins/vuetify'

const getters = {
  isLoading(state) {
    return state.loading
  },
  isMobile() {
    return !vuetify.framework.breakpoint.mdAndUp
  },
  isUserSignedIn(state) {
    return !!state.user
  },
  isSuperAdminUser(state) {
    return getters.isUserSignedIn(state) && !state.user.parentRef
  },
  isSuperOperatorUser(state) {
    return (
      getters.isUserSignedIn(state) &&
      state.user.adminPermissions &&
      state.user.adminRoles?.manageAdmins
    )
  },
  isAdminUser(state) {
    return (
      getters.isUserSignedIn(state) &&
      (state.user.adminPermissions || !state.user.parentRef)
    )
  },
  isOperatorUser(state) {
    return getters.isUserSignedIn(state) && state.user.parentRef
  },
  isDespachoUser(state) {
    return (
      getters.isUserSignedIn(state) && state.user.tipoEmpresa === 'ASESORIA'
    )
  },
  isClienteDespachoUser(state) {
    return (
      getters.isUserSignedIn(state) &&
      state.user.parentRef &&
      state.user.tipo === 'clienteDespacho'
    )
  },
  isColectivoUser(state) {
    return (
      getters.isUserSignedIn(state) &&
      state.user.tipoEmpresa === 'ASESORIACOLECTIVO'
    )
  },
  isEmpresaUser(state) {
    return getters.isUserSignedIn(state) && state.user.tipoEmpresa === 'EMPRESA'
  },
  isAllowed2FA(state) {
    return getters.isUserSignedIn(state) && state.user.allowed2FA
  },
  isAllowedAnalytics(state) {
    return (
      getters.isUserSignedIn(state) &&
      (getters.isSuperAdminUser(state) ||
        getters.isSuperOperatorUser(state) ||
        state.user.adminRoles?.allowedAnalytics)
    )
  },
  isNotificacionesActivas(state) {
    return (
      getters.isUserSignedIn(state) &&
      state.user.notificacionesActivasEmpresa &&
      state.user.notificacionesActivas
    )
  },
  isNotificacionesActivasEmpresa(state) {
    return (
      getters.isUserSignedIn(state) && state.user.notificacionesActivasEmpresa
    )
  },
  isFirmasActivas(state) {
    return (
      getters.isUserSignedIn(state) &&
      state.user.firmasActivasEmpresa &&
      state.user.firmasActivas
    )
  },
  isFirmasActivasEmpresa(state) {
    return getters.isUserSignedIn(state) && state.user.firmasActivasEmpresa
  },
  isInGroupUser(state) {
    return getters.isUserSignedIn(state) && state.user.groupsRef?.length > 0
  },
  isAdminAndCanManageUsers(state) {
    return (
      getters.isAdminUser &&
      state.user.adminPermissions &&
      state.user.adminRoles?.manageUsers
    )
  },
  isAdminAndCanManageCompanies(state) {
    return (
      getters.isAdminUser &&
      state.user.adminPermissions &&
      state.user.adminRoles?.manageCompanies
    )
  },
  isAdminAndCanManageGroups(state) {
    return (
      getters.isAdminUser &&
      state.user.adminPermissions &&
      state.user.adminRoles?.manageGroups
    )
  },
  isAdminAndCanManageAutomatedTasks(state) {
    return (
      getters.isAdminUser &&
      state.user.adminPermissions &&
      state.user.adminRoles?.manageAutomatedTasks
    )
  },
  isAdminAndCanManageFilters(state) {
    return (
      getters.isAdminUser &&
      state.user.adminPermissions &&
      state.user.adminRoles?.manageFilters
    )
  },
  isAdminAndCanUploadCertificates(state) {
    return (
      getters.isAdminUser &&
      state.user.adminPermissions &&
      state.user.adminRoles?.uploadCertificates
    )
  },
  isDemoEnv() {
    return process.env.VUE_APP_DEMO === 'demo'
  },
  isSingleSignOnUser(state) {
    const singleSignOn = state.userSubstitute
      ? state.userSubstitute?.singleSignOn
      : state.user?.singleSignOn
    return getters.isUserSignedIn(state) && singleSignOn
  },
  isExternalAuthUser(state) {
    const allowed = state.userSubstitute
      ? state.userSubstitute.externalAuth?.allowed
      : state.user.externalAuth?.allowed
    return getters.isUserSignedIn(state) && allowed
  },
  isExternalAuthRequired(state) {
    return state.userSubstitute
      ? state.userSubstitute.externalAuth?.required
      : state.user.externalAuth?.required
  },
  hasExternalsAuths(state) {
    return state.userSubstitute
      ? state.userSubstitute.externalAuth?.enabled
      : state.user.externalAuth?.enabled
  },
  isTermsAccepted(state) {
    if (getters.isOperatorUser(state)) return true
    // solo el superadmin de la empresa puede aceptar los terminos del servicio.
    if (getters.isSuperAdminUser(state)) {
      if (state.user?.legal?.haveAccept) {
        return state.user?.legal?.accepted
      } else {
        return true
      }
    } else {
      return true
    }
  },
  isLicenseJustRenewed(state) {
    // solo el superadmin de la empresa puede ver el mensaje de licencia renovada ok y darse por enterado
    if (getters.isSuperAdminUser(state)) {
      return state.user.estadoRenovacionLicencia == 'renovacionOK'
    } else {
      return false
    }
  },
  isCertificateAlert(state) {
    return state.user.lastCertificateError
  },
  isUpdatingNotifications(state) {
    return (
      state.progress &&
      state.progress.some(
        (item) => item.message === 'updatingNotificationsText'
      )
    )
  },
  inactivityLimit(state) {
    return state.user.securitySettings?.inactivityLimit ?? 0
  },
  isAllowedAbsenceManagement(state) {
    return getters.isUserSignedIn(state) && state.user.allowedAbsenceManagement
  },
  isSubstituteMode(state) {
    return getters.isUserSignedIn(state) && !!state.userSubstitute
  },
}

export default getters
