import { Auth, Firestore, UsersDoc } from '@/firebase-exports'
import {
  getOperatorFilters,
  getOperatorSubstitutionsForToday,
} from '@/services/operator-service'
import {
  getParentAttributes,
  getCifs,
  getOperatorGroups,
} from '@/services/user-service'
import getUserPages from '@/utils/get-user-pages'
import { planSubscription } from '@/services/plan-service'
import { notificationsSubscription } from '@/services/notification-service'
import { newsSubscription } from '@/services/news-service'
import { signaturesSubscription } from '@/services/signatures-service'
import { setBrand, resetBrand } from '@/utils/brand-utils'
import {
  getExpirationDate,
  calculateRenewalDate,
  formatDate,
  isLicenseExpirationReached,
  calculateEndGracePeriod,
  calculateDaysRemainingToDisable,
} from '@/utils/license-utils'

import router from '@/router'
import store from '@/store'
import i18n from '@/i18n'
import { listGroups } from '@/services/groups-service'
import { logoutExternalAuth } from '@/services/externals-auths-service'

const actions = {
  async fetchUser({ dispatch, commit }) {
    try {
      const firebaseUser = await Auth.getAuth().currentUser
      Auth.getAuth().currentUser.getIdToken(false)
      if (!store.state.userSubstitute) {
        // modo normal, no sustitucion
        if (firebaseUser) {
          var userUnsubscribe = Firestore.onSnapshot(
            UsersDoc(firebaseUser.uid),
            async (docSnap) => {
              if (!docSnap.exists()) {
                // Usuario no existe
                commit('setSnackbar', {
                  position: 'top',
                  type: 'error',
                  message: i18n.t('unauthorized'),
                })
                dispatch('logoutUnauthorized')
              } else {
                var firestoreUser = docSnap.data()

                // Delete some back variables that could be unsafe to store in front
                delete firestoreUser.password
                delete firestoreUser.passwordEmpresa
                delete firestoreUser.redmineKey
                delete firestoreUser.redmineProject

                let groups = []
                let operatorRoles = []
                let parentAttributes = {}
                let substitutionsToday = []

                let cifs = await getCifs(
                  firestoreUser.parentRef
                    ? firestoreUser.parentRef.id
                    : firebaseUser.uid,
                  firestoreUser.parentRef && firestoreUser
                )

                // Si es operador
                if (firestoreUser.parentRef) {
                  // Obtenemos los filters del operador
                  operatorRoles = await getOperatorFilters(firestoreUser)
                  parentAttributes = await getParentAttributes(
                    firestoreUser.parentRef.id
                  )
                  groups = await getOperatorGroups(firestoreUser)
                }

                // obtenemos las posibles sustituciones activas, sea operador o superadmin
                substitutionsToday = await getOperatorSubstitutionsForToday(
                  firestoreUser
                )

                var firestoreCopy = {
                  ...firestoreUser,
                  id: firebaseUser.uid,
                  groups,
                  cifs,
                  operatorRoles,
                  allNotifications: false,
                  ...parentAttributes,
                  substitutionsToday,
                }

                // Si es un operador y tiene autenticación externa restauramos los valores propios del usuario.
                if (
                  firestoreUser.parentRef &&
                  firestoreCopy.externalAuth &&
                  firestoreUser.externalAuth
                ) {
                  firestoreCopy.externalAuth.allowed =
                    firestoreUser.externalAuth.allowed
                  firestoreCopy.externalAuth.reference =
                    firestoreUser.externalAuth.reference
                  firestoreCopy.externalAuth.token =
                    firestoreUser.externalAuth.token
                }

                // Suscripción del plan
                await planSubscription(
                  firestoreUser.parentRef
                    ? firestoreUser.parentRef.id
                    : firebaseUser.uid,
                  firestoreCopy
                )

                // Suscripción de las notificaciones
                if (firestoreUser.notificacionesActivas)
                  await notificationsSubscription(
                    firestoreUser.parentRef
                      ? firestoreUser.parentRef.id
                      : firebaseUser.uid,
                    firestoreCopy
                  )

                // Suscripcion de firmas
                if (firestoreUser.firmasActivas)
                  await signaturesSubscription(
                    firestoreUser.parentRef
                      ? firestoreUser.parentRef.id
                      : firebaseUser.uid,
                    firestoreCopy
                  )

                // Personalizar marca blanca
                if (firestoreCopy.customBrand)
                  setBrand(firestoreCopy.customBrand)
                else if (store.state.brand) resetBrand()

                dispatch('configureUser', firestoreCopy)

                // Como he reconvertido un objeto en un array de objetos, tengo que limpiar el array
                // para evitar que se acumulen de nuevo los mismos mensajes que ya se muestran
                store.commit('resetProgress')

                // Check de certificados para mostrar la barra roja en la parte superior
                if (store.getters.isAdminUser) {
                  // Usuario sin activar, sin certificado
                  if (firestoreCopy.needsCertEmpresa) {
                    commit('setProgress', {
                      message: 'uploadCertificateWarning',
                      height: 50,
                      color: 'error',
                      movement: false,
                      link: {
                        text: 'redirectToConfiguration',
                        route: '/settings',
                      },
                    })
                  }

                  // Usuario con el certificado principal caducado
                  if (firestoreCopy.certificateExpiration) {
                    const certExpiration = firestoreCopy.certificateExpiration
                      .toDate()
                      .toISOString()
                      .slice(0, 10)
                    const today = new Date().toISOString().slice(0, 10)
                    if (certExpiration < today)
                      commit('setProgress', {
                        message: 'certificateExpiration.expiredWarning',
                        height: 50,
                        color: 'error',
                        movement: false,
                        link: {
                          text: 'redirectToConfiguration',
                          route: '/settings',
                        },
                      })
                  }

                  // Usuario con el certificado alternativo caducado
                  firestoreCopy.alternativeCertificates?.forEach(
                    (certificate) => {
                      const certExpiration = certificate.expiration
                        .toDate()
                        .toISOString()
                        .slice(0, 10)
                      const today = new Date().toISOString().slice(0, 10)
                      if (certExpiration < today)
                        commit('setProgress', {
                          message: 'certificateExpiration.expiredWarning',
                          height: 50,
                          color: 'error',
                          movement: false,
                          link: {
                            text: 'redirectToConfiguration',
                            route: '/settings',
                          },
                        })
                    }
                  )
                }

                // Check de licencia de pruebas para mostrar en el banner en la parte superior
                if (store.getters.isAdminUser) {
                  if (firestoreCopy.trialLicense) {
                    const trialLicenseExpirationISO =
                      firestoreCopy.plan.expirationLicense
                        .toDate()
                        .toISOString()
                        .slice(0, 10)
                    const today = new Date().toISOString().slice(0, 10)

                    // Convierte las cadenas de texto a objetos Date solo con la parte de la fecha (sin horas)
                    const expiration = new Date(trialLicenseExpirationISO)
                    const current = new Date(today)

                    // Calcula la diferencia en milisegundos entre ambas fechas
                    const differenceInTime =
                      expiration.getTime() - current.getTime()

                    // Convierte la diferencia a días (ignorando horas, minutos y segundos)
                    const daysRemaining = differenceInTime / (1000 * 3600 * 24)

                    // Divide la cadena en partes usando el guion como separador
                    const [year, month, day] =
                      trialLicenseExpirationISO.split('-')

                    // Reorganiza las partes en el formato a mostrar al usuario como parte del mensaje, p.e. "14/09/2024"
                    // Nota: he sustituido el guion por la barra porque si no al mostrarlo en el banner se parten en dos palabras
                    const formattedDate = `${day}/${month}/${year}`

                    if (daysRemaining > 0) {
                      commit('setProgress', {
                        message: 'trialLicenseWarning',
                        messageParams: {
                          trialDaysRemaining:
                            daysRemaining < 10
                              ? '0' + daysRemaining
                              : daysRemaining.toString(),
                          trialEndDate: formattedDate,
                          hightlightTextColor: 'black',
                          hightlightTextBackground: '#65d5dd',
                        },
                        height: 50,
                        color: '#65d5dd',
                        movement: false,
                      })
                    } else {
                      commit('setProgress', {
                        message: 'trialLicenseReached',
                        messageParams: {
                          trialEndDate: formattedDate,
                          hightlightTextColor: '#ffffff',
                          hightlightTextBackground: '#b60000',
                        },
                        height: 50,
                        color: 'error',
                        movement: false,
                      })
                    }
                  }
                }

                // Check de estado de renovacion de licencia para mostrar en el banner en la parte superior
                if (store.getters.isAdminUser) {
                  if (firestoreCopy.estadoRenovacionLicencia) {
                    const expirationDate = getExpirationDate(firestoreCopy)
                    const renewalDate = calculateRenewalDate(firestoreCopy, 30)
                    var msg = null
                    var backcolor = 'error'
                    var expirationGracePeriod = null
                    var daysRemainingToDisable = null
                    var hightlightTextColor = ''
                    var hightlightTextBackground = ''

                    switch (firestoreCopy.estadoRenovacionLicencia) {
                      case 'pendienteRespuestaRenovacion':
                        msg =
                          'licenseRenewalStatus.pendienteRespuestaRenovacion'
                        backcolor = '#65d5dd'
                        hightlightTextColor = 'black'
                        hightlightTextBackground = '#65d5dd'
                        break

                      case 'enProcesoRenovacion':
                        if (!isLicenseExpirationReached(firestoreCopy)) {
                          msg = 'licenseRenewalStatus.enProcesoRenovacion'
                          backcolor = '#65d5dd'
                          hightlightTextColor = 'black'
                          hightlightTextBackground = '#65d5dd'
                        } else {
                          msg = 'licenseInvoiceExpired'
                          backcolor = 'error'
                          hightlightTextColor = '#ffffff'
                          hightlightTextBackground = '#b60000'
                          expirationGracePeriod = calculateEndGracePeriod(
                            firestoreCopy,
                            30
                          )
                        }
                        break

                      case 'noRenueva':
                        if (!isLicenseExpirationReached(firestoreCopy)) {
                          msg = 'licenseRenewalStatus.noRenueva'
                          backcolor = 'error'
                          hightlightTextColor = '#ffffff'
                          hightlightTextBackground = '#b60000'
                        } else {
                          daysRemainingToDisable =
                            calculateDaysRemainingToDisable(firestoreCopy, 30)

                          msg =
                            daysRemainingToDisable > 0
                              ? daysRemainingToDisable == 1
                                ? 'licenseRenewalUnwantedSingular' // Si solo queda 1 día
                                : 'licenseRenewalUnwanted' // Si quedan más de 1 día
                              : 'licenseExpired' // Si ya expiró
                          backcolor = 'error'
                          hightlightTextColor = '#ffffff'
                          hightlightTextBackground = '#b60000'
                        }
                        break

                      case 'renovacionOK':
                        // no en un banner sino en un dialogo modal con boton, y solo superadmin
                        //msg = 'licenseRenewalStatus.renovacionOK'
                        //backcolor = '#acdcb0'
                        //hightlightTextColor = '#ffffff'
                        //hightlightTextBackground = '#acdcb0'
                        break
                    }

                    if (msg !== null) {
                      commit('setProgress', {
                        message: msg,
                        messageParams: {
                          expirationDate: formatDate(expirationDate),
                          renewalDate: formatDate(renewalDate),
                          ...(expirationGracePeriod !== null && {
                            expirationGrace: formatDate(expirationGracePeriod),
                          }),
                          ...(daysRemainingToDisable !== null && {
                            daysRemaining: daysRemainingToDisable,
                          }),
                          hightlightTextColor: hightlightTextColor,
                          hightlightTextBackground: hightlightTextBackground,
                        },
                        height: 50,
                        color: backcolor,
                        movement: false,
                      })
                    }
                  }
                }

                // Sustituciones
                //if (store.getters.isOperatorUser) {
                if (firestoreCopy.substitutionsToday.length > 0) {
                  // Armar un banner con un mensaje i18n que contiene una parte fija y otra variable, por ejemplo:
                  //"Con fecha de hoy está asignado como sustituto del siguiente operador/es: " + nombre-y-apellidos-op1 + ", " + nombre-y-apellidos-op2 + ... + nombre-y-apellidos-opN
                  const msg =
                    firestoreCopy.substitutionsToday.length == 1
                      ? 'substituteForSingular' // Sustituye a un único operador
                      : 'substituteFor' // Sustituye a más de un operador

                  commit('setProgress', {
                    message: msg,
                    messageParams: {
                      substitutions: firestoreCopy.substitutionsToday,
                      hightlightTextColor: 'black',
                      hightlightTextBackground: '#65d5dd',
                    },
                    height: 50,
                    color: '#65d5dd',
                    movement: false,
                  })
                }
                //}
                // fin de los banners

                firestoreUser.id = firebaseUser.uid
                const tokenExpiration =
                  firebaseUser.stsTokenManager.expirationTime
                const currentDate = new Date().getTime()
                const expirationTimeout = tokenExpiration - currentDate
                setTimeout(async () => {
                  await dispatch('fetchUser')
                }, expirationTimeout)

                if (!router.currentRoute.meta.userCanAccess()) {
                  router.push('/')
                }

                groups =
                  groups.length > 0
                    ? groups
                    : await listGroups(firebaseUser.uid)

                // If the user is an operator, groups have already been
                if (
                  groups.some((group) => group.unregisteredCifs?.length > 0)
                ) {
                  // If it is admin, set the pending dot in the cifs button
                  if (firestoreUser.parentRef && firestoreUser.adminPermissions)
                    commit('setPending', { cifs: true })
                  // If it is superadmin, set the pending dot in the groups button
                  else commit('setPending', { groups: true })
                }

                // Suscripción de las noticias
                if (!store.getters.isDemoEnv) await newsSubscription()
              }
            },
            (error) => {
              throw error
            }
          )
          return userUnsubscribe
        } else {
          throw new Error('User is not authenticated')
        }
      } else {
        // en modo sustitucion, tambien mantiene la sesion del usuario logged
        const tokenExpiration = firebaseUser.stsTokenManager.expirationTime
        const currentDate = new Date().getTime()
        const expirationTimeout = tokenExpiration - currentDate
        setTimeout(async () => {
          await dispatch('fetchUser')
        }, expirationTimeout)
      }
    } catch (err) {
      dispatch('configureUser', null)
      throw err
    }
  },
  /**
   * Sustitucion de un usuario ausente
   * @param {object} absentUser
   */
  async fetchAbsentUser({ dispatch, commit }, absentUser) {
    try {
      // obtener datos del usuario ausente desde Firestore
      if (absentUser && absentUser !== 'unselect') {
        var userUnsubscribe = Firestore.onSnapshot(
          UsersDoc(absentUser.id),
          async (docSnap) => {
            if (!docSnap.exists()) {
              // Usuario no existe
              commit('setSnackbar', {
                position: 'top',
                type: 'error',
                message: i18n.t('unauthorized'),
              })
              //dispatch('logoutUnauthorized')  // TODO ya veremos
            } else {
              var firestoreUser = docSnap.data()
              if (!firestoreUser.id) firestoreUser.id = docSnap.id

              // Delete some back variables that could be unsafe to store in front
              delete firestoreUser.password
              delete firestoreUser.passwordEmpresa
              delete firestoreUser.redmineKey
              delete firestoreUser.redmineProject

              let groups = []
              let operatorRoles = []
              let parentAttributes = {}
              let substitutionsToday = []

              let cifs = await getCifs(
                firestoreUser.parentRef
                  ? firestoreUser.parentRef.id
                  : absentUser.id,
                firestoreUser.parentRef && firestoreUser
              )

              // Si es operador
              if (firestoreUser.parentRef) {
                // Obtenemos los filters del operador
                operatorRoles = await getOperatorFilters(firestoreUser)
                parentAttributes = await getParentAttributes(
                  firestoreUser.parentRef.id
                )
                groups = await getOperatorGroups(firestoreUser)

                // el operador esta siendo sustituido, por tanto no puede tener sustituciones
              }

              var firestoreCopy = {
                ...firestoreUser,
                groups,
                cifs,
                operatorRoles,
                allNotifications: false,
                ...parentAttributes,
                substitutionsToday,
              }

              // Si es un operador y tiene autenticación externa restauramos los valores propios del usuario.
              if (
                firestoreUser.parentRef &&
                firestoreCopy.externalAuth &&
                firestoreUser.externalAuth
              ) {
                firestoreCopy.externalAuth.allowed =
                  firestoreUser.externalAuth.allowed
                firestoreCopy.externalAuth.reference =
                  firestoreUser.externalAuth.reference
                firestoreCopy.externalAuth.token =
                  firestoreUser.externalAuth.token
              }

              // Suscripción del plan
              await planSubscription(
                firestoreUser.parentRef
                  ? firestoreUser.parentRef.id
                  : absentUser.id,
                firestoreCopy
              )

              // Suscripción de las notificaciones
              if (firestoreUser.notificacionesActivas)
                await notificationsSubscription(
                  firestoreUser.parentRef
                    ? firestoreUser.parentRef.id
                    : absentUser.id,
                  firestoreCopy
                )

              // Suscripcion de firmas
              if (firestoreUser.firmasActivas)
                await signaturesSubscription(
                  firestoreUser.parentRef
                    ? firestoreUser.parentRef.id
                    : absentUser.id,
                  firestoreCopy
                )

              // Personalizar marca blanca
              if (firestoreCopy.customBrand) setBrand(firestoreCopy.customBrand)
              else if (store.state.brand) resetBrand()

              dispatch('configureUser', firestoreCopy)

              // No limpio el array de banners porque dejaria de ver el banner con los botones de usuarios a sustituir.
              // Dejo los mismo banners que hubiera, de momento.

              // Sustitucion en curso, nuevo banner
              if (store.getters.isSubstituteMode) {
                const msg = i18n.t('replacing', {
                  fullname: `${store.state.user.name || ''} ${
                    store.state.user.surname || ''
                  }`,
                })
                commit('setProgress', {
                  message: msg,
                  height: 30,
                  color: 'error',
                  movement: false,
                  single: true, // solo puede haber uno de este tipo simultaneamente.
                })
              }
              // fin de los banners

              // el usuario sustituido no esta logueado y no necesita refrescarse y obtener un nuevo token.

              if (!router.currentRoute.meta.userCanAccess()) {
                router.push('/')
              }

              groups =
                groups.length > 0 ? groups : await listGroups(absentUser.id)

              // If the user is an operator, groups have already been
              if (groups.some((group) => group.unregisteredCifs?.length > 0)) {
                // If it is admin, set the pending dot in the cifs button
                if (firestoreUser.parentRef && firestoreUser.adminPermissions)
                  commit('setPending', { cifs: true })
                // If it is superadmin, set the pending dot in the groups button
                else commit('setPending', { groups: true })
              }

              // Suscripción de las noticias
              if (!store.getters.isDemoEnv) await newsSubscription()
            }
          },
          (error) => {
            throw error
          }
        )
        return userUnsubscribe
      } else {
        if (absentUser === 'unselect') {
          dispatch('configureUser', absentUser)
          await dispatch('fetchUser')
        }
      }
    } catch (err) {
      // Se ha producido un error con el usuario a sustituir
      console.log('Error: ', err)
      dispatch('configureUser', 'unselect')
      throw err
    }
  },
  configureUser({ commit }, userData) {
    commit('setUser', userData)
    if (!userData) commit('setNavigationPages', {})
    else {
      var userPages = getUserPages()
      commit('setNavigationPages', userPages)
    }
  },
  async logout({ dispatch }) {
    dispatch('killListeners')
    await Auth.signOut(Auth.getAuth())
    if (store.getters.isExternalAuthUser) await logoutExternalAuth()
    dispatch('configureUser', null)
    dispatch('resetStore')
    router.replace('/')
    window.location.reload()
  },
  async logoutUnauthorized() {
    await Auth.signOut(Auth.getAuth())
    setTimeout(() => {
      window.location.reload()
    }, 1000)
  },
  killListeners({ commit }) {
    commit('killListenersMutation')
  },
  resetStore({ commit }) {
    commit('resetState')
  },
}

export default actions
