<template>
  <v-container>
    <v-row
      class="ma-2"
      justify="center"
      v-if="
        isNotificacionesActivas && isFirmasActivas && dashboardType !== 'empty'
      "
    >
      <v-btn
        data-v-step="200"
        elevation="0"
        x-large
        rounded
        @click="
          toggleDashboard(
            dashboardType === 'notifications' ? 'signatures' : 'notifications'
          )
        "
      >
        {{ $t(dashboardType) }}
        <v-icon v-if="dashboardType === 'notifications'"> mdi-mailbox </v-icon>
        <v-icon v-else-if="dashboardType === 'signatures'">
          mdi-draw-pen
        </v-icon>
      </v-btn>
    </v-row>
    <v-row class="ma-1">
      <transition name="rotate" mode="out-in">
        <DashboardNotificationsContainer
          v-if="dashboardType === 'notifications'"
          key="dashboard-notifications-container"
        />
        <DashboardSignaturesContainer
          v-else-if="dashboardType === 'signatures'"
          key="dashboard-signatures-container"
        />
        <DashboardEmptyContainer v-else key="dashboard-empty-container" />
      </transition>
    </v-row>
  </v-container>
</template>

<script>
import DashboardNotificationsContainer from '@/components/dashboard/DashboardNotificationsContainer'
import DashboardSignaturesContainer from '@/components/dashboard/DashboardSignaturesContainer'
import DashboardEmptyContainer from '@/components/dashboard/DashboardEmptyContainer'

import { mapGetters } from 'vuex'

export default {
  components: {
    DashboardNotificationsContainer,
    DashboardSignaturesContainer,
    DashboardEmptyContainer,
  },
  beforeMount() {
    this.updateDashboardType()
  },
  async created() {},
  watch: {
    isNotificacionesActivas: function () {
      this.updateDashboardType()
    },
    isFirmasActivas: function () {
      this.updateDashboardType()
    },
  },
  data() {
    return {
      dashboardType: 'empty',
    }
  },
  computed: {
    ...mapGetters(['isNotificacionesActivas', 'isFirmasActivas']),
  },
  methods: {
    updateDashboardType() {
      this.dashboardType = this.isNotificacionesActivas
        ? 'notifications'
        : this.isFirmasActivas
        ? 'signatures'
        : 'empty'
    },
    toggleDashboard(newType) {
      this.dashboardType = newType
    },
  },
}
</script>

<style lang="scss" scoped>
.rotate-enter-active,
.rotate-leave-active {
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.rotate-enter,
.rotate-leave-to {
  transform: rotateY(-90deg);
  opacity: 0;
}

.rotate-enter-to,
.rotate-leave {
  transform: rotateY(0deg);
  opacity: 1;
}

/* Ajusta la perspectiva y la posición de la cámara */
.v-row {
  perspective: 1000px;
  transform-style: preserve-3d;
  position: relative;
}

/* Ajusta la posición de los elementos internos */
.v-row > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

/* Gira el segundo elemento 180 grados para mostrarlo en la parte frontal */
.v-row > *:last-child {
  transform: rotateY(180deg);
}
</style>
