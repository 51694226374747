<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <NotificationsList
          :loading="loading"
          :cifs="cifs"
          :notificationsMax="notificationsMax"
          :notificationsUsed="notificationsUsed"
          @setCancelLoad="setCancelLoad"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NotificationsList from '@/components/notifications/NotificationsList'
import { getCifs } from '@/services/user-service'
import { mapGetters } from 'vuex'

export default {
  components: {
    NotificationsList,
  },
  data() {
    return {
      loading: true,
      cifs: [],
    }
  },
  async created() {
    const userId = this.isOperatorUser
      ? this.$store.state.user.parentRef.id
      : this.$store.state.user.id

    const cifsList = await getCifs(
      userId,
      this.isOperatorUser ? this.$store.state.user : false
    )
    this.cifs = this.isOperatorUser
      ? cifsList.filter((cif) => {
          return (
            cif.notificacionesActivas &&
            this.$store.state.user.cifsPermissions[cif.numeroDocIdentidad]
              .notificacionesActivas &&
            !cif.deletionDate
          )
        })
      : cifsList.filter((cif) => {
          return cif.notificacionesActivas && !cif.deletionDate
        })

    this.loading = false
  },
  computed: {
    ...mapGetters(['isOperatorUser']),
    notificationsUsed() {
      return this.$store.state.plan?.notificationsUsed
    },
    notificationsMax() {
      return this.$store.state.plan?.notificationsMax
    },
  },
  methods: {
    setCancelLoad() {
      this.loading = false
      // Si el usuario ha detenido la carga, redirigir al Dashboard, la lista de cifs o de notificaciones no esta completa.
      this.$router.push({ name: 'DashboardView' })
    },
  },
}
</script>

<style lang="scss" scoped></style>
